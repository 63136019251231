.page {
    display: block;
    background: #f3f3f4ab;
    justify-content: center;
    height: auto;
}

.letreiro {
    display: flex;
    flex-direction: column;
}

.letreiro h1 {
    color: #000033;
    font-size: 3.5em;
    line-height: 2em;
    letter-spacing: -0.035em;
    margin: .5 0 1em 0;
    align-self: center;
}


.header,
.header_cnpj {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2em;
    margin-top: 4em;
}

.header h1 {
    color: #000033;
    font-size: 2.5em;
    line-height: 1.5em;
    letter-spacing: -0.025em;
    margin: .5 0 1em 0;
    align-self: center;
}

.header p {
    color: #4b4b4b;
    align-self: center;
}

.header_cnpj h1 {
    color: #000033;
    font-size: 3.5em;
    line-height: 1.5em;
    letter-spacing: 0.1em;
    margin: .5 0 1em 0;
    align-self: center;
}

.header_cnpj p {
    color: #5c83be;
    align-self: center;
    font-size: 1.1em;
    font-weight: 500;
}

.container {
    max-width: 1400px;
    margin: 0 auto;
    background-color: #fff;
    padding: 1.5rem;
    padding-bottom: 2em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 15px;
    height: fit-content;


}

.container_header {
    background-color: #000033;
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 20px;
    padding: .5em 1.5em;
    max-width: 1400px;
    margin: 0 auto;
    margin-bottom: 1.5em;
}

.container_header p {
    font-family: 'Source Sans Pro', sans-serif;
    color: #d8d8d8cc;
    font-size: 16px;
    margin-bottom: -.3em;
}

.form {
    max-width: 1100px;
    margin: 0 auto;
}

.form_group {
    margin-bottom: 1em;
    margin-top: 2em;
    margin-left: 2em;
    margin-right: 2em;

}

.form_group p {
    font-size: 14px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #8b8a8a;
    margin-top: 0;
}

.form_group h5 {
    margin-bottom: 0;
    color: #140c44
}

.form_group_cnpj {
    margin-bottom: 1em;
    margin-top: 2em;
    margin-left: 2em;
    margin-right: 2em;

}

.form_group_cnpj p {
    font-size: 14px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #ff0303d0;
    margin-top: -1rem;
}

.form_group_cnpj h5 {
    margin-bottom: 0;
    color: #140c44
}

/* 
.title_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #e5edf5;
    padding: .3rem;
    align-items: center;
    align-content: center;
}
.title_container h6 {
color: #000033;
font-family: 'Source Sans Pro', sans-serif;
} */

.form_group_label {
    color: #2596be;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.35em;
    line-height: 1em;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5em;
    margin-top: -.3em;

}

.form_group p {
    margin-bottom: -.3em;
}

.label_doc {
    color: #2596be;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5em;
    margin-top: 1em;
    
}

.form_control_cnpj {
    color: #cfcfcf;
    border-radius: 5px;
    background-color: transparent;
    display: block;
    width: 100%;
    margin-bottom: 1.5em;
    margin-top: -.3em;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form_control,
.form_control_textarea,
.select_input .input {

    color: #303030;
    border: 1px solid #ecebfa;
    border-radius: 5px;
    background-color: transparent;
    margin-bottom: 1.5em;
    margin-top: -.3em;
    appearance: none;

}

.form_control_textarea {
    height: 120px;
    width: 520px;
    color: #000000;
}

.form_control:hover,
.form_control_textarea:hover,
.select_input:hover {
    border: 0.5px solid #80abbbcb;

}

.form_control:focus,
.form_control_textarea:focus,
.select_input:focus {
    border: 2px solid #2596be;
    outline: none;
}

.form_control_select {
    margin-bottom: 2em;
}


.actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.form_check {
    margin-top: .3em;
    margin-left: 2em;
    align-self: center;
    float: inline-start;
    width: 1em;
    -webkit-transform: scale(1.5);
}

.mapList {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Card {
    display: flex;
    border: 1.5px dashed #E1E3EA;
    background-color: #ffffff;
    margin: 1em auto;
    width: 100%;
    padding: .3em 1em;
    font-family: 'Source Sans Pro', sans-serif;
    color: #181C32;
    font-size: 30px;
}

.Card a{
    font-family: 'Source Sans Pro', sans-serif;
    color: #181C32;
    font-weight: 600;
    font-size: 18px;
    margin-left: .5em;

}

.input { 
    margin: 2em auto;
    margin-left: .5em;
    font-size: 14px;
    color: #838383;
}

.btn_atualizar {
    display: flex;
    justify-content: center;
    background-color: #3E97FF;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    padding: .2em 2em;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    margin-top: 2em;
    margin-left: 2em;
    color: #ffffff;
    text-decoration: none;
    font-size: 14px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    line-height: 2em;
}

.btn_atualizar:hover {
    background-color: #2688f7;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}

.btn {
    display: flex;
    justify-content: center;
    background-color: #000033;
    transition: 0.2s;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    padding: .2em;
    float: right;
    cursor: pointer;
    display: inline-block;
    padding: .3em 3em;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    margin-top: 2em;
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    line-height: 2em;

}

.btn:hover {
    background-color: #2596be;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}

.btn a:hover {
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
}

.togglebtn {
    width: 7rem;
    height: 2.4rem;
    margin-top: -.3rem;

}

.btn_voltar {
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
    border: none;
    padding: 1em;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    margin-top: 2em;
    margin-left: 5em;
}

.btn_voltar a {
    color: #000033;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    line-height: 2em;
    }

.btn_voltar:hover {
    border-bottom: 1px solid #222;

}

.img {
    width: fit-content;
    align-self: center;
    }

.Modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Modal_header{
display: flex;
flex-direction: column-reverse;
border: none;
border-radius: 15px 15px;
background-color: #ffffff;
margin-bottom: -1em;
visibility: .5;
font-size: 18px;

}

.Modal_header img{
    padding: 2em 0;
    width: 120px;
    
    }

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    color: #2c2c2c;
    text-align: center;
    font-size: 18px;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
    margin-bottom: 2em;
}

.Table {
    width: 98%;
    align-self: center;
    border-collapse: collapse;
    border-spacing: 0;
    font-family: 'Source Sans Pro', sans-serif;
    margin: 0 auto;
    margin-bottom: 2em;
}

.Table thead {
    font-size: 20px;
    font-weight: 600;

}

.th {
    width: 90%;
}

.th_btn {
    width: 12em;
}

.btn_delete {
    color: #f14141;
    background-color: transparent;
    border: none;
    font-size: 25px;
    text-align:center;
}

.btn_delete:hover {
    color: #9b2727;
}