.page {
    display: flex;
    flex-direction: column;
    background: rgb(241, 246, 249);
    min-height: 100vh;
    font-family: 'Source Sans Pro', sans-serif;
    box-sizing: border-box;
    padding-bottom: 2em;
}

.header {
    display: flex;
    max-width: 68vw;
    margin: 0 auto;
    padding: 1em;
}

.header h1 {
    font-size: 25px;
    font-weight: 600;
}

.gerenciar {
    margin-top: 2em;
    margin: 0 auto;
}

.box_gerenciar {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: none;
    border-radius: 10px;
    min-width: 60vw;
    min-height: 20vh;
    padding: 1em;
    margin-top: 1em;
    margin: 0 auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0;
}

.box_gerenciar h4 {
    font-weight: 500;
    font-size: 16px;
    color: #154360;
    text-align: center;
    padding-bottom: .5em;
    font-weight: bold;
}

.box_gerenciar li {
    list-style: none;
    border-bottom: 1px solid #e2e2e2;
    font-size: 18px;
    font-weight: 500;
    color: #707070;
}

.form_label_gerenciar {
    font-size: 18px;
    color: #21618C;
    font-weight: 600;
}

.btn_send {
    background-color: #fff;
    border-bottom: 1px solid #a5aaad;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    box-sizing: border-box;
    color: #a5aaad;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    line-height: 0.3;
    padding: .5em 1em;
    text-align: center;
    text-decoration: none #a5aaad solid;
    text-decoration-thickness: auto;
    transition: all .1s cubic-bezier(.4, 0, .2, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-right: 1em;
}

.btn_send:hover {
    color: #1B4F72;
}

.btn_send svg {
    font-size: 18px;
    margin-left: .3em;
    margin-bottom: .1em;
}

.btn_ativo {
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -3px;
    color: #21618C;
    border-bottom: 1px solid #21618C;
}

.btn_excluir {
    background-color: #fff;
    border-bottom: 1px solid #E74C3C;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    box-sizing: border-box;
    color: #E74C3C;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    line-height: 0.3;
    padding: .5em 1em;
    text-align: center;
    text-decoration: none #E74C3C solid;
    text-decoration-thickness: auto;
    transition: all .1s cubic-bezier(.4, 0, .2, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-right: 1em;
}

.btn_excluir:hover {
    color: #CB4335;
}

.btn_excluir svg {
    font-size: 18px;
    margin-left: .3em;
    margin-bottom: .1em;
}

.btn_ativoExcluir {
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -3px;
    color: #CB4335;
    border-bottom: 1px solid #CB4335;
}

.btn_print {
    background-color: #eceeef;
    border-radius: 6px;
    font-weight: 600;
    outline: 0;
    border: 0;
    cursor: pointer;
    margin-right: 2em;
    padding: 0 .5em;
}

.btn_print svg {
    color: #34495E;
    font-weight: bold;
    font-size: 20px;
}


.btn_print:hover {
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
    box-shadow: 0 5px 5px rgba(0, 0, 0, .1)
}

.btn_atualizar {
    background-color: #E5E7E9;
    border: 1px solid transparent;
    border-radius: 5px;
    box-sizing: border-box;
    color: #626567;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.15385;
    margin: 0;
    outline: none;
    padding: 5px .8em;
    position: relative;
    text-align: center;
    touch-action: manipulation;
    vertical-align: baseline;
    white-space: nowrap;
}

.btn_atualizar svg {
    font-size: 18px;
    margin-left: .3em;
    margin-bottom: .2em;
    color: #626567;
}

.btn_atualizar:hover,
.btn_atualizar:focus {
    background-color: #D7DBDD;
}

.btn_atualizar:focus {
    box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
}

.btn_atualizar:active {
    background-color: #D7DBDD;
    box-shadow: none;
}


.Box_container {
    display: flex;
    flex-direction: row;
    background: rgb(241, 246, 249);
    height: auto;
    justify-content: center;
    max-width: 65vw;
    margin: 0 auto;
    margin-top: 2em;
}

.img {
    width: 5vw;
    margin: 0 auto;
}

.Box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #ffffff;
    border: none;
    border-radius: 10px;
    min-width: 15vw;
    max-height: 58.3vh;
    min-height: auto;
    padding: 0 2em;
    float: right;
    margin-top: 2em;
    margin-right: 1.5em;
    margin-left: 1.5em;
    gap: .5em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0;
}

.Box strong {
    font-size: 16px;
    color: #333;
}

.Box p {
    font-size: 16px;
    color: #404040;
    margin-bottom: .3em;
}

.Box_Endereco {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: sticky;
    background-color: #ffffff;
    border: none;
    border-radius: 10px;
    width: 20vw;
    min-height: 30.5vh;
    max-height: auto;
    padding: 1em 2em;
    padding-bottom: 0;
    margin-top: 2em;
    margin-right: 1.5em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0;

}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(102, 145, 231, .18);
    align-self: center;
    width: 80%;
    border: none;
    border-radius: 8px;
}

.title h5 {
    margin: .3em;
    font-size: 16px;
    font-weight: 600;
    color: #6691e7;
}

.Box_Endereco strong {
    font-size: 15px;
    color: #333;
}

.Box_Endereco p {
    font-size: 15px;
    color: #404040;
    margin-bottom: .3em;
}

.Box_Banco {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    background-color: #ffffff;
    border: none;
    border-radius: 10px;
    width: 20vw;
    min-height: 26vh;
    max-height: auto;
    padding: 2em;
    margin-top: 1em;
    margin-right: 1.5em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0;

}

.title_banco {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(237, 94, 94, .1);
    align-self: center;
    width: 80%;
    border: none;
    border-radius: 8px;
}

.title_banco h5 {
    margin: .3em;
    font-size: 16px;
    font-weight: 600;
    color: #ed5e5e;
}


.Box_Banco strong {
    font-size: 15px;
    color: #333;
}

.Box_Banco p {
    font-size: 15px;
    color: #404040;
    margin-bottom: .1em;
}

.Box_Empresa {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: sticky;
    background-color: #ffffff;
    border: none;
    border-radius: 10px;
    width: 23vw;
    min-height: 30.5vh;
    padding: 2em;
    padding-bottom: 0;
    margin-top: 2em;
    margin-right: 1.5em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0;
}


.title_empresa {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(19, 197, 107, .1);
    align-self: center;
    width: 80%;
    border: none;
    border-radius: 8px;
    margin-top: 0;
}

.title_empresa h5 {
    margin: .3em;
    font-size: 16px;
    font-weight: 600;
    color: #13c56b;
}

.Box_Empresa strong {
    font-size: 15px;
    color: #333;
}

.Box_Empresa p {
    font-size: 15px;
    color: #404040;
    margin-bottom: .5em;
}

.Box_Obs {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #ffffff;
    border: none;
    border-radius: 10px;
    width: 23vw;
    min-height: 26vh;
    padding: 2em;
    margin-top: 1em;
    margin-bottom: 2em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0;
}

.Box_Obs p {
    font-size: 16px;
    color: #707070;
    margin: .3em;
    position: static;
}

.title_Obs {
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
}

.title_Obs svg {
    align-self: center;
    margin-top: .2em;
    font-size: 30px;
    color: #02a2ca;
}

.title_Obs h5 {
    margin: .3em;
    margin-bottom: -.3em;
    color: #3f3f3f;
    font-weight: bold;
}

.atividade_container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 60vw;
}

.card_atividade {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: none;
    border-radius: 10px;
    min-width: 60vw;
    min-height: 5vh;
    padding: 1em;
    margin-top: 1em;
    margin-bottom: .5em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0;
}

.title_atividade {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #ffffff;
    width: 100%;
    border-bottom: 1px solid rgb(206, 206, 206);
    margin-bottom: 1em;
    margin-top: -.3em;
    padding: .5em;
}

.title_atividade h5 {
    font-size: 22px;
    font-weight: 600;
    color: #2C323F;
}

.card_atividade li {
    list-style: none;
    border-bottom: 1px solid #e2e2e2;
    font-size: 18px;
    font-weight: 500;
    color: #707070;
}


.VerAquivo_btn {
    appearance: none;
    background-color: #FAFBFC;
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
    box-sizing: border-box;
    color: #24292E;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    list-style: none;
    padding: 6px 16px;
    position: relative;
    transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    word-wrap: break-word;
}

.VerAquivo_btn:hover {
    background-color: #F3F4F6;
    text-decoration: none;
    transition-duration: 0.1s;
}

.VerAquivo_btn:disabled {
    background-color: #FAFBFC;
    border-color: rgba(27, 31, 35, 0.15);
    color: #959DA5;
    cursor: default;
}

.VerAquivo_btn:active {
    background-color: #EDEFF2;
    box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
    transition: none 0s;
}

.VerAquivo_btn:focus {
    outline: 1px transparent;
}

.VerAquivo_btn:before {
    display: none;
}

.VerAquivo_btn:-webkit-details-marker {
    display: none;
}

.box_container2 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-height: fit-content;
    margin: 0 auto;
    margin-top: 2em;
    margin-bottom: 1em;

    width: 100%;
}

.form_group {
    margin-bottom: 2em;
    width: 95%;
}

.boxlista {
    overflow-y: auto;
    /* Adicione esta linha para adicionar a barra de rolagem vertical */
    max-height: 750px;
    background-color: #fff;

    border-radius: 10px;
    /* Ajuste a altura máxima conforme necessário */
}

.boxarquivo {
    overflow-y: auto;
    /* Adicione esta linha para adicionar a barra de rolagem vertical */
    /*max-height: 750px; */
    /* Ajuste a altura máxima conforme necessário */
}

























































.btn {
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
    border: none;
    padding: .2em;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    margin-top: 3em;
    margin-left: 5em;
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;

}

.btn a {
    color: #222;
    text-decoration: none;
}

/* .btn svg {
        color: #222;
        text-decoration: none;
        font-size: 20px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 500;
        margin-top: .4em;
        margin-right: .3em;

    } */


.btn:hover {
    border-bottom: 1px solid #222;

}

.mapList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    max-width: 1400px;
    margin: 0 auto;
    margin-top: 2em;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1em 0;
}

.Card {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.5px dashed #d1d1d1;
    background-color: #ffffff;
    margin: 1em;
    width: 500px;
    height: 500px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #ad0000;
    font-weight: 600;
    font-size: 20px;
}

.Card a {
    font-family: 'Source Sans Pro', sans-serif;
    color: #636363;
    font-weight: 600;
    font-size: 14px;
}

.btn_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin-bottom: 2em;
}

.btn_aprovar {

    background-color: #3498DB;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    margin-top: 3em;
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    padding: .3em .5em;
}

.btn_aprovar:hover {
    background-color: #2E86C1;
    box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
    border: none;
    color: #ffffff;
}

.btn_reprovar {
    background-color: #C0392B;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    margin-top: 3em;
    margin-right: 2em;
    margin-left: 2em;
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    padding: .3em .5em;
}

.btn_reprovar:hover {
    background-color: #A93226;
    box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
    border: none;
    color: #ffffff;
}

.btn_mensagem {
    background-color: rgb(248, 183, 30);
    cursor: pointer;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    margin-top: 3em;
    margin-left: 2em;
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-size: 16px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    padding: .3em .5em;
}

.btn_mensagem:hover {
    background-color: rgb(250, 207, 108);
    box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
    border: none;

    color: #ffffff;
}

.form_label_encaminhamento {
    font-size: 18px;
    color: #3498DB;
    font-weight: 600;
}

/* .form_label_encaminhamento svg {
    font-size: 22px;
    margin-bottom: .2em;
} */

/* .btn_send {
    background-color: #3498DB;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    white-space: nowrap;
    color: #ffffff;
    font-size: 16px;
}

.btn_send svg {
    font-size: 18px;
    margin-left: .3em;
    margin-bottom: .1em;
} */

.Modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Modal_header {
    display: flex;
    flex-direction: column-reverse;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
    margin-bottom: -1em;
    visibility: .5;
}

.Modal_header img {
    padding: 2em 0;
    width: 120px;

}

.Modal_header svg {
    font-size: 40px;
    color: #4b4b4b;
}

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
    color: #707070;
    text-align: center;
    font-size: 14px;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
    margin-bottom: 2em;
}

.Modal_body p {
    font-size: 15px;
    margin-bottom: 1em;
}

.Modal_body p svg {
    font-size: 18px;
    margin-bottom: .2em;
    margin-right: .3em;
    color: #30a565;
}

.Modal_body textarea {
    width: 430px;
    border: 1px solid #adadad;
    border-radius: 10px;
    margin-bottom: 2em;
    min-height: 100px;
}

.Modal_body textarea:focus {
    border: 2px solid #adadad;
    outline: none;
}

/* .Modal_body button {
    font-size: 15px;
    background-color: #02a2ca;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: .5em 2em;
    margin-bottom: 1em;
}

.Modal_body button:hover {
    background-color: #57abc0;
    color: #ffffff;

} */

.label_modal {
    color: #1b4f72;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 1em;

}

.btn_salvar {
    color: #ffffff;
    background-color: #0679F3;
    border: 1px solid #0679F3;
    border-radius: 5px;
    margin: .5em;
    margin-top: 2em;
    width: 100px;
}

.btn_salvar:hover {
    background-color: #0663c7;
    border: none;
}

.btn_cancelar {
    color: #ffffff;
    background-color: #C1C1C1;
    border: 1px solid #C1C1C1;
    border-radius: 5px;
    margin: .5em;
    margin-top: 2em;
    width: 100px;
}

.btn_cancelar:hover {
    background-color: #999999;
    border: none;
}

.box_container {
    max-width: 70vw;
    display: flex;
    flex-direction: column;
}

.box_solic {
    background-color: #ffffff;
    border-radius: 10px;
    min-width: 70vw;
    padding: 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
}

.box_solic h2 {
    font-weight: 500;
    font-size: 24px;
    color: #626567;
}

.box_solic p {
    color: #707070;
    font-size: 16px;
    margin-bottom: 0;
}


.box_historico {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1em;
    max-width: 70vw;
    margin: 0 auto;
}


.box_historico h4 {
    font-weight: 500;
    font-size: 16px;
    color: #154360;
    text-align: center;
    padding-bottom: .5em;
}

.box_historico p {
    color: #707070;
    font-size: 15px;
    padding: .5em;
}

.mapList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    min-width: 70vw;
    min-height: 50vh;
    margin: 0 auto;
    margin-top: 1em;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1em 0;
}

.Card {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.5px dashed #d1d1d1;
    background-color: #ffffff;
    margin: .5em;
    width: 45%;
    font-family: 'Source Sans Pro', sans-serif;
    color: #ad0000;
    font-weight: 600;
    font-size: 20px;
}

.Card a {
    font-family: 'Source Sans Pro', sans-serif;
    color: #636363;
    font-weight: 600;
    font-size: 14px;
}

.encaminhamento {
    margin-top: 1em;
    margin-bottom: 1em;
}

.box_encaminhamento {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: none;
    border-radius: 10px;
    max-width: 70vw;
    min-height: 220px;
    padding: 1em;
    margin-top: 1em;
    margin: 0 auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0;
}

.box_encaminhamento li {
    list-style: none;
    border-bottom: 1px solid #e2e2e2;
    font-size: 18px;
    font-weight: 500;
    color: #707070;
}

.form_label_encaminhamento {
    font-size: 18px;
    color: #3498DB;
    font-weight: 600;
}

/* .btn_send {
    background-color: #3498DB;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    white-space: nowrap;
    color: #ffffff;
    font-size: 16px;
}

.btn_send svg {
    font-size: 18px;
    margin-left: .3em;
    margin-bottom: .1em;
} */

.title_encaminhamento {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #ffffff;
    width: 100%;
    border-bottom: 1px solid rgb(206, 206, 206);
    margin-bottom: 1em;
    margin-top: -.3em;
    padding: .5em;
}

.title_encaminhamento h5 {
    font-size: 22px;
    font-weight: 600;
    color: #2C323F;
}


/* .Arquivo_container {
                    align-self: center;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    max-width: 80%;
                }
    .Card {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    padding: 1.5em;
                    margin: 2em;
                    width: 300px;
                    height: 120px;
                }

    .Arquivo_container p {
                    color: #737373;
                    padding: 1em;
                    margin-top: 2em;
                } */