.page {
    background: #f3f3f4ab;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 2em;
}

.voltar {
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
    border: none;
    padding: .2em;
    white-space: nowrap;
    margin-top: 3em;
    margin-left: 5em;


}

.voltar a {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    text-align: center;
}

.voltar svg {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    margin-right: .3em;

}

.voltar:hover {
    border-bottom: 1px solid #222;

}

.form_label {
    font-size: 16px;
    color: #1B4F72;
    font-weight: 600;
    padding: .3em;
}

.textarea {
    color: #222;
    /* border: 1px solid #ced4da;
    border-radius: 5px; */
    background-color: transparent;
    margin-bottom: 1.5em;
    appearance: none;
    height: 10em;
    width: 100%;
}

.enviar_btn {
    background-color: #1B4F72;
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    height: 40px;
    line-height: 20px;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 10px 16px;
    position: relative;
    text-align: center;
    transition: color 100ms;
    vertical-align: baseline;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
}

.enviar_btn:hover,
.enviar_btn:focus {
    background-color: #21618C;
}

.Modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Modal_header {
    display: flex;
    flex-direction: column-reverse;
}

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    color: #2c2c2c;
    text-align: center;
    font-size: 18px;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
    margin-bottom: 2em;
}

.Modal_body p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
    color: #808080;
    text-align: center;
    font-size: 16px;
}

.Modal_header img {
    width: 50px;
    margin: 0 auto;
}

.table {
    font-size: 14px;
}

.table thead {
    color: #21618C;
    font-weight: 600;
    padding: .3em;
}

voltar {
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
    border: none;
    padding: .2em;
    white-space: nowrap;
    margin-top: 3em;
    margin-left: 5em;


}

.voltar a {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    text-align: center;
    border: none;
}

.voltar svg {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    margin-right: .3em;
    border: none;

}


.voltar:hover {
    border-bottom: 1px solid #222;

}

.btn_salvar {
    color: #ffffff;
    background-color: #0679F3;
    border: 1px solid #0679F3;
    border-radius: 5px;
    margin: .5em;
    width: 100px;
}

.btn_salvar:hover {
    background-color: #0663c7;
    border: none;
}

.btn_cancelar {
    color: #ffffff;
    background-color: #C1C1C1;
    border: 1px solid #C1C1C1;
    border-radius: 5px;
    margin: .5em;
    width: 100px;
}

.btn_cancelar:hover {
    background-color: #999999;
    border: none;
}