.page {
    display: block;
    background: #f3f3f4ab;
    min-height: 100vh;
    justify-content: center;
    padding-bottom: 2em;
}

    .page h2 {
    color: #fafafa;
    }

.btn_container {
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    padding: 2em 0;
    max-width: 1200px;
}

.create_btn {
    display: flex;
    justify-content: center ;
    background-color: #2557a3;
    transition: 0.2s;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    font-family: 'Source Sans Pro', sans-serif;
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    max-height: 2em;
    line-height: 2em;
    padding: 0 1em;
    white-space: nowrap;
}

.create_btn a {
    text-align: center;
    text-decoration: none;
    color: #ffffff;
}

.create_btn svg {
    margin-left: .3em;
    margin-bottom: .3em;
}

.create_btn:hover {
    background-color: #062654;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.create_btn a:hover {
    color: #ffffff;
}

.header {
    background-color: #98d0eb83;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0.3em 1em;
    border-radius: 5px 5px 0 0;
    }
    .header h1 {
        font-size: 22px;
        font-weight: 600;
        margin-top: .3em ;
    }

    .header select {
        border-radius: 5px;
        width: 3em;
        height: 2em;
        background-color: #fffffff3;
        outline: none;
        border: none;
        margin-top: .3em;
    }
    .header input {
        border: 1px solid #98cfeb;
        border-radius: 5px;
        width: 18em;
        height: 2.3em;
        margin-top: .2em;
        color: #525252;
    }
    .header input:hover {
        border: 1px solid #0090c5;
    }
    .header input:focus {
        border: 2px solid #0090c5;
    }


.table_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffff;
    border-radius: 5px;
    max-width: 1200px;
    margin: 0 auto;
}
.table {
    width: 95%;
    align-self: center;
    border-collapse: collapse;
    border-spacing: 0;
    font-family: 'Source Sans Pro', sans-serif;
}

    .table thead {

        display: table-header-group;
        vertical-align: middle;
        border-top: 0 #ffff;
        border-left: 0 #ffff;
        border-right: 0 #ffff;
        border-bottom: 2px solid #e2e2e2;
        font-weight: 600;
        font-size: 16px;
        color: #222;
    }

    /* .th_numero {
        margin-left: .3em;
    } */

    .th_btn {
        width: 12em;
    }


    .table tbody {
        vertical-align: middle;
        border-top: 0 #ffff;
        border-left: 0 #ffff;
        border-right: 0 #ffff;
        border-bottom: 1px solid #e2e2e2;
        font-weight: 400;
        font-size: 14px;
        color: #222;
    }

    /* .td_numero {
        font-weight: 700;
        color: black;
    } */



.td_btn {
    text-align: center;
}


.btn_look {
    background-color: #e0ecfa;
    border-radius: 6px;
    font-weight: 600;
    outline: 0;
    border: 0;
    cursor: pointer;
    width: 3em;
    height: 2em;
    margin: 1em;
    margin-left: .5em ; 
}

.btn_look svg {
    color: #3E97FF;
    font-weight: bold;
    font-size: 20px;
}


.btn_look:hover {
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-box-shadow: 0 5px 5px rgba(0,0,0,.1);
    box-shadow: 0 5px 5px rgba(0,0,0,.1)
}

.Modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Modal_header{
display: flex;
flex-direction: column-reverse;
border: none;
border-radius: 15px 15px;
background-color: #ffffff;
margin-bottom: -1em;
visibility: .5;
font-size: 18px;

}

.Modal_header img{
    padding: 2em 0;
    width: 120px;
    
    }

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    color: #2c2c2c;
    text-align: center;
    font-size: 18px;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
}

