.page {
    display: flex;
    flex-direction: column;
    background: rgb(241, 246, 249);
    min-height: 100vh;
    font-family: 'Source Sans Pro', sans-serif;
    box-sizing: border-box;
    padding-bottom: 2em;
}

.btn {
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
    border: none;
    padding: .2em;
    text-align: center;
    white-space: nowrap;
    margin-top: 3em;
    margin-left: 5em;
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;

}

.btn a {
    color: #222;
    text-decoration: none;
}

/* .btn svg {
        color: #222;
        text-decoration: none;
        font-size: 20px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 500;
        margin-top: .4em;
        margin-right: .3em;

    } */


.btn:hover {
    border-bottom: 1px solid #222;

}



.box_container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-width: 90%;
    max-width: 90%;
    max-height: fit-content;
    margin: 0 auto;
    margin-top: 2em;
    padding: 1em;
    background-color: transparent;
}


.box_projeto {
    display: flex;
    flex-direction: column;
    background-color: #1b2850;
    border-radius: 10px;
    padding: 1em;
    min-width: 30%;
    max-height: auto;
    margin: 1em;
}

.box_projeto h1 {
    font-size: 20px;
    color: #F7F9F9;
    margin-bottom: 2em;
}

.box_projeto h3 {
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 1em;
}

.box_projeto span {
    font-size: 26px;
    color: #ffffff;
}

.box_projeto:hover span {
    font-size: 28px;
    color: #ffffff;
    transition: all .5s ease;
}

.box_projeto p {
    font-size: 16px;
    color: #F7F9F9;
}


.box_objetivo {
    display: flex;
    flex-direction: column;
    background-color: #ff9f43;
    border-radius: 10px;
    padding: 1em;
    min-width: 30%;
    max-height: auto;
    margin: 1em;
}

.box_objetivo h1 {
    font-size: 20px;
    color: #F7F9F9;
    font-weight: 600;
}

.box_objetivo p {
    font-size: 16px;
    color: #ffffff;
    margin-bottom: .3em;
}

.box_objetivo span {
    font-size: 26px;
    color: #ffffff;
}

.box_objetivo:hover span {
    font-size: 28px;
    color: #ffffff;
    transition: all .5s ease;
}

.box_origem {
    display: flex;
    flex-direction: column;
    background-color: #00cfe8;
    border-radius: 10px;
    padding: 1em;
    min-width: 30%;
    max-height: auto;
    margin: 1em;
}

.box_origem h1 {
    font-size: 24px;
    color: #F7F9F9;
    margin-bottom: 2em;
    font-weight: 600;
}

.box_origem p {
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
}

.box_origem span {
    float: right;
    font-size: 26px;
    color: #ffffff;
}

.box_origem:hover span {
    font-size: 28px;
    color: #ffffff;
    transition: all .5s ease;
}




.btn_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 85%;
    max-height: fit-content;
    margin: 0 auto;
    margin-top: 2em;
    background-color: transparent;
    border-radius: 10px;
}

/* .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 2em;

}

.header h2 {
    font-size: 25px;
    font-weight: 600;
    color: #5a5a5a;
}

.header p {
    font-size: 18px;
    color: #a5a5a5;
} */

.edit_buttons {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 1em;
    width: 50%;
    border-radius: 5px;
    margin-right: 1em;
}

.edit_buttons h1 {
    font-size: 18px;
    font-weight: 600;
    color: #566573;
    margin: 0 auto;
}

.edit2 {
    background: #f6f7f8;
    padding: .5em;
    min-width: 200px;
    border: none;
    margin: 1em;
    margin-right: 1.5em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    text-decoration: none;
    color: #919ca7;
    font-weight: 600;

}

.edit {
    background: #f6f7f8;
    padding: .5em;
    min-width: 200px;
    border: none;
    margin: 1em;
    margin-right: 1.5em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

}

.edit:hover {
    background-color: #edf1f5;
    font-size: 18px;
}

.edit a {
    text-decoration: none;
    color: #919ca7;
    font-weight: 600;
}

.edit a:hover {
    text-decoration: none;
    color: #58b8e4;

}

.add2 {
    background: #f6f7f8;
    padding: .5em;
    min-width: 200px;
    border: none;
    margin: 1em;
    margin-right: 1.5em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    text-decoration: none;
    color: #919ca7;
    font-weight: 600;

}

.add_buttons {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 1em;
    width: 50%;
    border-radius: 5px;
    margin-left: 1em;
    margin-right: 1em;
}

.add_buttons h1 {
    font-size: 18px;
    font-weight: 600;
    color: #566573;
    margin: 0 auto;
}

.add {
    background: #f6f7f8;
    padding: .5em;
    min-width: 200px;
    border: none;
    margin: 1em;
    margin-right: 1.5em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.add:hover {
    background-color: #edf1f5;
    font-size: 18px;
}

.add a {
    text-decoration: none;
    color: #919ca7;
    font-weight: 600;
}

.add a:hover {
    text-decoration: none;
    color: #52BE80;

}

.relatorio_buttons {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 1em;
    width: 50%;
    border-radius: 5px;
    margin-left: 1em;

}

.relatorio_buttons h1 {
    font-size: 18px;
    font-weight: 600;
    color: #566573;
    margin: 0 auto;
}

.relatorio2 {
    background: #f6f7f8;
    padding: .5em;
    min-width: 200px;
    border: none;
    margin: 1em;
    margin-right: 1.5em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    text-decoration: none;
    color: #919ca7;
    font-weight: 600;

}

.relatorio {
    background: #f6f7f8;
    padding: .5em;
    min-width: 200px;
    border: none;
    margin: 1em;
    margin-right: 1.5em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.relatorio:hover {
    background-color: #edf1f5;
    font-size: 18px;
}

.relatorio a {
    text-decoration: none;
    font-size: 16px;
    color: #919ca7;
    font-weight: 600;
}

.relatorio a:hover {
    text-decoration: none;
    color: #f8cc1c;

}

.Modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Modal_header {
    display: flex;
    flex-direction: column-reverse;
}

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    color: #2c2c2c;
    text-align: center;
    font-size: 18px;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
    margin-bottom: 2em;
}

.Modal_body p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
    color: #808080;
    text-align: center;
    font-size: 16px;
}

.Modal_header img {
    width: 50px;
    margin: 0 auto;
}



/* .edit_concedente {
    background: #d6f5d9 ;
    padding: 1em;
    border: none;
    border-radius: 5px;
    margin: 1em;
    margin-right: 1.5em;
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out

}

.edit_concedente:hover {
    background-color: #eafcf6;

}

.edit_concedente a {
    text-decoration: none;
    color: #28a745;
    font-weight: 600;
}

.edit_concedente a:hover {
    text-decoration: none;
    color: #28a745;

}

.edit_elemento {
    background: #faf6d7  ;
    padding: 1em;
    border: none;
    border-radius: 5px;
    margin: 1em;
    margin-right: 1.5em;
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out

}

.edit_elemento:hover {
    background-color: #fcfbea;

}

.edit_elemento a {
    text-decoration: none;
    color: #e9ab2e;
    font-weight: 600;
}

.edit_elemento a:hover {
    text-decoration: none;
    color: #e9ab2e ;

}

.edit_meta {
    background: #f5d6d6   ;
    padding: 1em;
    border: none;
    border-radius: 5px;
    margin: 1em;
    margin-right: 1.5em;
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out

}

.edit_meta:hover {
    background-color: #fcedea;

}

.edit_meta a {
    text-decoration: none;
    color: #f96868;
    font-weight: 600;
}

.edit_meta a:hover {
    text-decoration: none;
    color: #f96868  ;

}

.edit_fase {
    background: #e5d6f5    ;
    padding: 1em;
    border: none;
    border-radius: 5px;
    margin: 1em;
    margin-right: 1.5em;
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out

}

.edit_fase:hover {
    background-color: #f3eafc;

}

.edit_fase a {
    text-decoration: none;
    color: #926dde;
    font-weight: 600;
}

.edit_fase a:hover {
    text-decoration: none;
    color: #926dde   ;

}

.edit_valores {
    background: #e2eff8    ;
    padding: 1em;
    border: none;
    border-radius: 5px;
    margin: 1em;
    margin-right: 1.5em;
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out

}

.edit_valores:hover {
    background-color: #f0f5f8;

}

.edit_valores a {
    text-decoration: none;
    color: #48b0f7 ;
    font-weight: 600;
}

.edit_valores a:hover {
    text-decoration: none;
    color: #48b0f7    ;

} */