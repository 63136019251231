.page {
    background: #f3f3f4ab;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2em;
}

.voltar {
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
    border: none;
    padding: .2em;
    white-space: nowrap;
    margin-top: 3em;
    margin-left: 5em;


}

.voltar a {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    text-align: center;
}

.voltar svg {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    margin-right: .3em;

}


.voltar:hover {
    border-bottom: 1px solid #222;

}

/* .form_container {
    background-color: #ffffff;
    border-top: 5px solid #2471A3;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
   
align-self: center;

margin: auto 0;
padding: 2em;
width: 1200px;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

*/
.form_group_label {
    color: #2557a3;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    line-height: 1em;
}

.form_control {

    color: #707070;
    border: 0.5px solid #2557a33f;
    border-radius: 5px;
    background-color: #fff;
    margin-bottom: 1.5em;
    margin-top: -.3em;
}

.form_control:hover {
    border: 0.5px solid #2557a3;

}

.form_control:focus {
    border: 2px solid #062654;
    outline: none;
}

.finalizar_btn {
    color: #ffffff;
    font-weight: 600;
    padding: .1em .3em;
    background-color: #2471A3;
    border: 1px solid #2471A3;
    border-radius: 5px;
    transition: all .3s ease-in;
    margin: 2em auto .5em auto;
    width: 10vw;
}

.finalizar_btn:hover {
    background-color: #1B4F72;
    border: 1px solid #1B4F72;
    color: #fff;
}

.btn_AnexarQuadro {
    color: #626567;
    font-weight: 600;
    font-size: 14px;
    padding: .1em .3em;
    background-color: #EAECEE;
    border: 1px solid #EAECEE;
    border-radius: 5px;
    margin: 2em auto .5em auto;
    width: 10vw;
}

.btn_AnexarQuadro:hover {
    background-color: #B3B6B7;
    border: 1px solid #B3B6B7;
    color: #fff;
}

.Modal_header {
    display: flex;
    flex-direction: column-reverse;
    color: #4b4b4b;
}

.Modal_header svg {
    font-size: 40px;
    color: #4b4b4b;
}

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #808080;
    text-align: center;
    font-size: 18px;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
}

.Modal_body p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;
    color: #5e5e5e;
    text-align: center;
    font-size: 16px;
}

.Modal_body img {
    width: 40px;
    margin: 0 auto;
}

.Modal_body h6 {

    font-weight: 400;
    color: #ad0101;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    padding: 1em;
    margin-bottom: 1em;
}