.page {
    display: flex;
    background: rgb(241, 246, 249);
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 2em 8em;
}

.container {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 800px;
    max-width: 1400px;
    margin: 0 auto;
    background-color: #fff;
    padding: 1.5rem;
    padding-bottom: 2em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 15px;
    height: fit-content;
}

.container h1{
    color: #000033;
    font-size: 4em;
    line-height: 1.5em;
}

.container p{
    color: #999999;
    font-size: 1.2em;
    font-weight: 400;
}

.img {
    width: auto;
}

.btn {
    display: flex;
    justify-content: center ;
    background-color: #2596be;
    transition: 0.2s;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    padding: .2em;
    float: right;
    cursor: pointer;
    display: inline-block;
    padding: .3em 3em;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    margin-top: 2em;

    }

    .btn a {
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    line-height: 2em;
    }

    .btn:hover {
        background-color: #2562be;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    }

    .btn a:hover {
        color: #fff;
        font-weight: 500;
        font-size: 20px;
        font-family: 'Source Sans Pro', sans-serif;
    }
