.page {
    display: block;
    background: #f3f3f4ab;
    justify-content: center;
    min-height: 100vh;
    padding-bottom: 2em;
}

.btn {
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
    border: none;
    padding: .2em;
    text-align: center;
    white-space: nowrap;
    margin-top: 3em;
    margin-left: 5em;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;

}

.btn a {
    color: #222;
    text-decoration: none;
}

.btn svg {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    margin-right: .3em;

}


.btn:hover {
    border-bottom: 1px solid #222;

}

.container {
    display: flex;
    max-width: 1400px;
    min-height: fit-content;
    min-height: 30vh;
    margin: 0 auto;
    background-color: #fff;
    padding: 1.5rem;
    padding-bottom: 5em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;

}

.table_container {
    display: flex;
    max-width: 250px;
    padding: 2em;
}

.table {
    width: 100%;
    margin: 0 auto;

}

.table th {
    color: #010913;
    font-size: 18px;
    font-weight: 600;
    background-color: #98d0eb83;
}

.th_btn {
    width: 3em;
}

.btn_abrirform {
    color: #3E97FF;
    background-color: transparent;
    border: none;
    font-size: 25px;
    margin: 0 auto;
}

.btn_abrirform:hover {
    color: #357ed3;
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
}

.form_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 1000px;
    margin-top: 2em;
    margin-left: 2em;
    /* position: relative; */
    min-height: 35vh;   
}

.form_container h3 {
    align-self: center;
    margin-top: 1.5em;
    color: #aaaaaa;
    font-size: 25px;
}

.form_control {
    
    color: #707070;
    border: 0.5px solid #2557a33f;
    border-radius: 5px;
    background-color: transparent;
    margin-bottom: 1em;
    margin-top: -.3em;
}

.form_control:hover {
    border: 0.5px solid #2557a3;

}

.form_control:focus {
    border: 2px solid #062654;
    outline: none;
} 

.form_group_label {
    color: #2596be;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.35em;
    line-height: 1em;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5em;
    margin-top: -.3em;

}

.select_lable {

    color: #2596be;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.35em;
    line-height: 1em;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5em;
    margin-top: -1em;
}

.form_group_label p {
    color: #868686;
    margin-top: -.5em;
    font-weight: 400;
}

.form_group h5 {
    color: #062654;
    margin-bottom: 2em;
    margin-top: -.4em;
}

.inserir_btn {
    font-weight: 600;
    color: #e9b408;
    background-color: #fff3d2;
    border-radius: 5px;
    border: none;
    color: #e9b408;
    padding: 0.4em;
    font-size: 16px;
    line-height: 1.5;
    max-width: 300px;
    margin: 0 auto;
    margin-top: 1em;
    margin-bottom: 1em;
}
.inserir_btn svg {
    margin-bottom: .1em;
    margin-left: .3em;
}

.inserir_btn:hover {
    background-color: #e9b408;
    color: #ffffff;
}

.container_table_valores {
    display: block;
    max-width: 1400px;
    min-height: fit-content;
    min-height: 30vh;
    margin: 0 auto;
    margin-top: 4em;
    background-color: #fff;
    padding: 1.5rem;
    padding-bottom: 2em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
}

.btn_edit {
    background-color: #ecdef5;
    border-radius: 6px;
    font-weight: 600;
    outline: 0;
    border: 0;
    cursor: pointer;
    width: 2.5em;
    height: 2em;
    margin-left: .5em ; 
}

.btn_edit svg {
    color: #7239ea;
    font-weight: bold;
    font-size: 20px;
}

.btn_edit:hover {
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-box-shadow: 0 5px 5px rgba(0,0,0,.1);
    box-shadow: 0 5px 5px rgba(0,0,0,.1)
}

.btn_delete {
    background-color: #faecec;
    border-radius: 6px;
    font-weight: 600;
    outline: 0;
    border: 0;
    cursor: pointer;
    width: 2.5em;
    height: 2em;
    margin-left: .5em ; 
}

.btn_delete svg {
    color: #ea3939;
    font-weight: bold;
    font-size: 20px;
}

.btn_delete:hover {
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-box-shadow: 0 5px 5px rgba(0,0,0,.1);
    box-shadow: 0 5px 5px rgba(0,0,0,.1)
}

.Modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Modal_header{
display: flex;
flex-direction: column-reverse;
border: none;
border-radius: 15px 15px;
background-color: #ffffff;
margin-bottom: -1em;
visibility: .5;
font-size: 18px;
color: #4b4b4b;

}

.Modal_header img{
    padding: 2em 0;
    width: 120px;
    
    }
    
    .Modal_header svg {
        font-size: 40px;
        color: #df2f2fb4;
    }

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    color: #2c2c2c;
    text-align: center;
    font-size: 18px;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
    margin-bottom: 2em;
}
