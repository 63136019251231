.page {
    background: #f3f3f4ab;
    min-height: 100vh;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em;
}

.form {

    background-color: #fff;
    padding: 1.5rem;
    border-radius: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    min-width: 50vw;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
}

.header h1 {
    color: #1b4f72;
    font-size: 30px;
    line-height: 1.5em;
    letter-spacing: -0.025em;
    margin: .5 0 1em 0;
    align-self: center;
}

.form_group {
    margin: 0 auto;
    max-width: 80%;
    margin-bottom: 2em;
    margin-top: 2em;
    border: 1px dashed #4681a831;
    border-radius: 15px;
    padding: 2em;
}

.form_group_label {
    color: #5dade2;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.35em;
    line-height: 1em;
    font-size: 18px;
    line-height: 1.5em;
    margin-top: -.3em;
}

.form_control {

    color: #707070;
    border: 0.5px solid #2557a33f;
    border-radius: 5px;
    background-color: transparent;
    margin-bottom: 1.5em;
    margin-top: -.3em;
}

.form_control:hover {
    border: 0.5px solid #2557a3;

}

.form_control:focus {
    border: 2px solid #062654;
    outline: none;
}

.validacao_senha {
    margin-top: -1em;
    list-style: none;
}

.validacao_senha li {
    font-size: 13px;
    color: #aaaaaa;
}

.btn {
    display: flex;
    justify-content: center;
    background-color: #1b4f72;
    color: #ffffff;
    text-decoration: none;
    transition: 0.2s;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    max-height: 2em;
    line-height: 2em;
    padding: 0 2em;
    text-align: center;
    white-space: nowrap;
    margin: 0 auto;
    margin-top: 1em;

}

.btn svg {
    margin-left: .4em;
    margin-bottom: .2em;
}

.btn:hover {
    background-color: #2275acc4;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.Modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Modal_header {
    display: flex;
    flex-direction: column-reverse;
}

.Modal_header img {
    padding: 2em 0;
    width: 150px;
}

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 2em;
}

.Modal_body p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
    color: #808080;
    text-align: center;
    font-size: 16px;
}