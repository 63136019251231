/* print.css */

/* @media print {
    /* Estilos específicos de impressão */
    /* .page {
        background: rgb(255, 255, 255);
        font-family: "Source Sans Pro", Helvetica, sans-serif;
    }
    
    .container {
        display: grid;
        margin: 0 auto;
        border: none;
        border-radius: 5px;
        background-color: #ffffff;
        width: 100%;
    } */ 
    
    /* .content {
        padding: .5em;
        padding-top: 1em;
        display: flex;
        flex-direction: column;
        justify-content: center;
    } */
    
    /* .header {
        border-bottom: 1px solid #3872b98c;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: .5em;
    
    } */

    @media print {
    
    .printheader h3 {
        font-size: 14px;
        align-self: center;
        color: #5D6D7E;
        font-weight: 600;
    }
    
    .printheader p {
        margin-left: 1em;
        font-size: 12px;
        font-weight: 500;
        color: #85929E;
    }
    
    .printprojeto {
        font-size: 18px;
        font-weight: 600;
        margin-top: 1em;
        margin-bottom: .5em;
        color: #1B2631;
    }
    
    .printTable_consolidado {
        font-size: 14px;
        color: #34495E;
        padding-left: 7px;
        font-weight: bold;
    
    }
    
    .printTable_consolidado_head{
        padding: .3em;
        font-size: 12px;
        font-weight: bold;
        background-color: #D6DBDF;
    }

    .printTable_consolidado_body{
        padding: .3em;
        font-size: 11px;
    }

    .printTable_consolidado_total{
        padding: .3em;
        font-size: 13px;
        font-weight: bold;
    }
    /* .Tipo_Despesa h6 {
        font-size: 16px;
        color: #182f4b;
        margin-bottom: 1em;
        font-weight: 600;
    }
    
    .Tipo_Despesa h5 {
        font-weight: 600;
        color: #F2F4F4;
        font-size: 18px;
    }
    
    .Tipo_Despesa p {
        font-size: 14px;
        color: #222;
    } */
    
    .printMetas h6 {
        font-size: 14px;
        color: #56606d;
        font-weight: 600;
        margin-bottom: .3em;
    }
    
    .printMetas h5 {
        font-weight: 700;
        color: #0e1c2c;
        font-size: 14px;
    }
    
    .printMetas p {
        font-size: 11px;
        color: #222;
    }
    
    .consolidado_metas {
        font-size: 14px;
        color: #34495E;
        padding-left: 7px;
        font-weight: bold;
    }
    
    .Metas table {
        margin-bottom: 2em;
    }
}
    
  