.page {
    display: block;
    background: #f3f3f4ab;
    justify-content: center;
    min-height: auto;
    padding: 2em 4em;
}

.voltar {
    display: flex;
    justify-content: center;
    background-color: #f3f3f4ab;
    cursor: pointer;
    border: none;
    padding: .2em;
    white-space: nowrap;
    margin-top: 3em;
    margin-left: 5em;


}

.voltar a {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    text-align: center;
}

.voltar svg {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    margin-right: .3em;

}


.voltar:hover {
    border-bottom: 1px solid #222;

}


.letreiro {
    display: flex;
    align-items: center;
    justify-content: center;
}

.letreiro h1 {
    color: #000033;
    font-size: 3em;
    line-height: 2em;
    letter-spacing: -0.035em;
    margin: .5 0 1em 0;
    align-self: center;
}


.header,
.header_cnpj {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2em;
    margin-top: 4em;
}

.header h1 {
    color: #000033;
    font-size: 2.5em;
    line-height: 1.5em;
    letter-spacing: -0.025em;
    margin: .5 0 1em 0;
    align-self: center;
}

.header p {
    color: #4b4b4b;
    align-self: center;
}

.header_cnpj h1 {
    color: #000033;
    font-size: 3.5em;
    line-height: 1.5em;
    letter-spacing: 0.1em;
    margin: .5 0 1em 0;
    align-self: center;
}

.header_cnpj p {
    color: #5c83be;
    align-self: center;
    font-size: 1.1em;
    font-weight: 500;
}

.container {
    max-width: 1400px;
    margin: 0 auto;
    margin-bottom: 2em;
    background-color: #fff;
    padding: 2em;
    padding-bottom: 2em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 15px;
    height: fit-content;
    border-left: 5px solid #1F618D;


}

.container p {
    font-size: 14px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #8b8a8a;
    margin: 0;
}

.container_header {
    background-color: #ffffff;
    font-family: 'Source Sans Pro', sans-serif;
    color: #000033;
    font-size: 24px;
    font-weight: 600;
    padding: 0;
    max-width: 1300px;
    margin: 0 auto;
    margin-bottom: 1.5em;
    margin-top: -.5em;

}

.container_header p {
    font-family: 'Source Sans Pro', sans-serif;
    color: #d8d8d8cc;
    font-size: 24px;
    margin-bottom: -.3em;


}

.form {
    max-width: 1100px;
    margin: 0 auto;
}

.form_group {
    margin-bottom: 1em;
    margin-top: 1em;
    margin-left: 2em;
    margin-right: 2em;

}
/* 
.form_group p {
    font-size: 12px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #8b8a8a;
    margin-top: 0;
}

.form_group h5 {
    margin-bottom: 0;
    color: #140c44
} */

.form_group_cnpj {
    margin-bottom: 1em;
    margin-top: 2em;
    margin-left: 2em;
    margin-right: 2em;

}

.form_group_cnpj p {
    font-size: 14px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #ff0303d0;
    margin-top: -1rem;
}

.form_group_cnpj h5 {
    margin-bottom: 0;
    color: #140c44
}

/* 
.title_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #e5edf5;
    padding: .3rem;
    align-items: center;
    align-content: center;
}
.title_container h6 {
color: #000033;
font-family: 'Source Sans Pro', sans-serif;
} */

.form_group_label {
    color: #2596be;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.35em;
    line-height: 1em;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5em;
    margin-top: -.3em;

}

.form_group p {
    margin-bottom: -.3em;
}

.label_doc {
    color: #2596be;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5em;
    margin-top: 1em;

}

.form_control_cnpj {
    color: #cfcfcf;
    border-radius: 5px;
    background-color: transparent;
    display: block;
    width: 100%;
    margin-bottom: 1.5em;
    margin-top: -.3em;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form_control_cnpj p {
    color: red;
    font-size: 14px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    margin-top: .5rem;
}

.form_control,
.form_control_textarea,
.select_input .input {

    color: #303030;
    border: 1px solid #ecebfa;
    border-radius: 5px;
    background-color: transparent;
    margin-bottom: 1.5em;
    margin-top: -.3em;
    appearance: none;

}

.form_control_textarea {
    height: 120px;
    width: 520px;
    color: #000000;
}

.form_control:hover,
.form_control_textarea:hover,
.select_input:hover,
input:hover {
    border: 0.5px solid #80abbbcb;

}

.form_control:focus,
.form_control_textarea:focus,
.select_input:focus,
input:focus {
    border: 2px solid #2596be;
    outline: none;
}


.actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.form_check {
    margin-top: .3em;
    margin-left: 2em;
    align-self: center;
    float: inline-start;
    width: 1em;
    -webkit-transform: scale(1.5);
}


/* para select único */
/* .select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    margin: 0 auto;

}
.select_lable {
    margin-bottom: 1.5em;
    color: #386cb9;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.35em;
    line-height: 1em;
    font-size: 18px;
    line-height: 1.5em;
    margin-top: -.3em;
    margin-right: 1rem;
}

.inserir_btn {
    background-color: #1299f3;
    border-color: #128ef3;
    color: #fff;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.7em;
    border-radius: 3px;
    min-width: fit-content;
    margin-bottom: 2.1em;
    margin-left: 1em;
}
.inserir_btn > svg {
    fill: #fff;
    gap: .2em;
    margin-bottom: .3em;
} */

.mapList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.Card {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #c7c7c7;
    background-color: #ffffff;
    margin: 1em;
    width: 250px;
    padding: 1em 0;
    font-family: 'Source Sans Pro', sans-serif;
    color: #ad0000;
    font-weight: 600;
    font-size: 16px;
}

.Card a {
    font-family: 'Source Sans Pro', sans-serif;
    color: #636363;
    font-weight: 600;
    font-size: 14px;
}

.btn {
    display: flex;
    justify-content: center;
    background-color: #000033;
    transition: 0.2s;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    padding: .2em;
    width: fit-content;
    float: right;
    cursor: pointer;
    display: inline-block;
    padding: .3em 3em;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    margin-top: 2em;
    margin-bottom: 1em;
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    line-height: 2em;

}

.btn:hover {
    background-color: #2596be;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}

.btn a:hover {
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
}

.togglebtn {
    width: 7rem;
    height: 2.4rem;
    margin-top: -.3rem;

}

.alert {
    color: red;
    font-size: 14px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    margin-top: .5rem;

}

.Modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Modal_header {
    display: flex;
    flex-direction: column-reverse;
}

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    color: #2c2c2c;
    text-align: center;
    font-size: 18px;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
    margin-bottom: 2em;
}

.Modal_body p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
    color: #808080;
    text-align: center;
    font-size: 16px;
}

.Modal_body img {
    width: 50px;
    margin: 0 auto;
}