.page {
    display: block;
    background: #f3f3f4ab;
    min-height: 100vh;
    justify-content: center;
    padding-bottom: 2em;
}

.page h2 {
    color: #fafafa;
}

.voltar {
    background-color: transparent;
    display: flex;
    justify-content: center;
    cursor: pointer;
    border: none;
    padding: .2em;
    white-space: nowrap;
    margin-top: 3em;
    margin-left: 5em;
}

.voltar a {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    text-align: center;
}

.voltar svg {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    margin-right: .3em;

}


.voltar:hover {
    border-bottom: 1px solid #222;

}



.header {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 60vw;
    margin: 0 auto;
    padding: 1em;
    border-radius: 5px 5px 0 0;
}

.header h1 {
    font-size: 22px;
    font-weight: 600;
    margin-top: .3em;
}

/* .header select {
    border-radius: 5px;
    width: 3em;
    height: 2em;
    background-color: #fffffff3;
    outline: none;
    border: none;
    margin-top: .3em;
} */

.input_container {
    width: 30%;
    border: rgb(247 250 252);
    background-color: rgb(247 250 252);
    border-radius: 10px;
    display: flex;
    align-self: flex-start;
    height: 2.5em;
}

.input_container svg {
    font-size: 2.5em;
    color: #222;
    padding: .3em;
}

.input_container input {
    background-color: transparent;
    border: none;
    color: #525252;
    width: 80%;
}

.btn_container {
    display: flex;
    justify-content: flex-end;
    width: 50%;
}

.lancar_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1em;
    background-color: rgb(247 250 252);
    transition: 0.2s;
    cursor: pointer;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    font-family: 'Source Sans Pro', sans-serif;
}

.lancar_btn a {
    text-align: center;
    text-decoration: none;
    color: #3E97FF;
}

.lancar_btn a svg {
    color: #222;
}

.lancar_btn:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.lancar_btn a:hover {
    color: #154360;
}


.table_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffff;
    border-radius: 5px;
    max-width: 60vw;
    margin: 0 auto;
}

.table {
    width: 95%;
    align-self: center;
    border-collapse: collapse;
    border-spacing: 0;
    font-family: 'Source Sans Pro', sans-serif;
}

.table thead {

    display: table-header-group;
    vertical-align: middle;
    border-top: 0 #ffff;
    border-left: 0 #ffff;
    border-right: 0 #ffff;
    border-bottom: 2px solid #e2e2e2;
    font-weight: 600;
    font-size: 16px;
    color: #222;
}

/* .th_numero {
        margin-left: .3em;
    } */

.th_btn {
    width: 3vw;
}


.table tbody {
    vertical-align: middle;
    border-top: 0 #ffff;
    border-left: 0 #ffff;
    border-right: 0 #ffff;
    border-bottom: 1px solid #e2e2e2;
    font-weight: 400;
    font-size: 14px;
    color: #222;
}

.td_delete {
    text-align: center;

}

.td_delete button {
    border: none;
    background-color: transparent;
}

.td_delete svg {
    font-size: 20px;
    color: #C0392B;
}

.td_delete svg:hover {
    font-size: 20px;
    color: #922B21;
}

.td_edit {
    text-align: center;

}

.td_edit button {
    border: none;
    background-color: transparent;
}

.td_edit svg {
    font-size: 20px;
    color: #AEAEAE;
}

.td_edit svg:hover {
    font-size: 20px;
    color: #909090;
}

.btn_look {
    background-color: #e0ecfa;
    border-radius: 6px;
    font-weight: 600;
    outline: 0;
    border: 0;
    cursor: pointer;
    width: 3em;
    height: 2em;
    margin: 1em;
    margin-left: .5em;
}



.btn_look svg {
    color: #3E97FF;
    font-weight: bold;
    font-size: 20px;
}


.btn_look:hover {
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
    box-shadow: 0 5px 5px rgba(0, 0, 0, .1)
}

.Modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Modal_header {
    display: flex;
    flex-direction: column-reverse;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
    margin-bottom: -1em;
    visibility: .5;
    font-size: 18px;

}

.Modal_header img {
    padding: 2em 0;
    width: 120px;

}

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    color: #2c2c2c;
    text-align: center;
    font-size: 18px;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
}