.Login_header {
    background: #ffffff;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
}


/* .img_texto{
  width: 270px;
  height: 90px;
  padding: 0.3em;
} */

.Login {
  
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-image: url(../img/Login.png);
  background-size: cover;
  background-attachment:local;
}

.Login_container {
  background-color: #ffffff;
  padding: 5em 2em;
  border-radius: 10px;
  box-shadow: rgba(51, 51, 60, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
 }

 .img_logo {
  width: 95px;
  margin-bottom: 5em;
  margin-top: -3em;
  align-self: center;
  
}

 .Login_container h2 {
   color: #062654;
   font-family: 'Catamaran', sans-serif;
   font-family: 'Open Sans', sans-serif;
   font-size: 30px;
   font-weight:600;
   margin-bottom: 1.5em;
   margin-top: -1.5em;
   text-align: center;

 }

 .LoginAlerta{
  color: #f70707;
  font-family: 'Catamaran', sans-serif;
  font-size: 15px;
  text-align: center;
  display: block;

}

 


.form_control {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    width: 40vh;
    height: auto;
    border: 1px #bdbdbd;
    font-family: 'Source Sans Pro', sans-serif;
    
    
}



.form_control label {
    
    font-weight: 600;
    margin-left: 2.5em;
    margin-top: 1em;
    color: #1d7899;
    

}

.form_control input {
    /* margin: 0.5em 0;
    padding: 0.7em 1em;
    border-radius: 5px;
    border: 1px solid #bdbdbd;
    background-color: #F8F8F8; */

    width: 80%;
    padding: 5px;
    border: 0;
    border-bottom: 2px solid rgb(200,200,200);
    outline: 0;
    font-size: 16px;
    transition: .5s ease;
    align-self: center;
}

.form_control input:focus {
  border-bottom: 2px solid rgba(127, 195, 235, 0.788);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  outline: 0;
} 

.form_control input:hover {
  border-bottom: 2px solid rgba(127, 195, 235, 0.788);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  outline: 0; 
} 

.Modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Modal_header{
display: flex;
flex-direction: column-reverse;
border: none;
border-radius: 15px 15px;
background-color: #ffffff;
margin-bottom: -1em;
visibility: .5;
font-size: 18px;

}

.Modal_header img{
  padding: 2em 0;
  width: 120px;
  
  }

.Modal_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 600;
  color: #2c2c2c;
  text-align: center;
  font-size: 18px;
  border: none;
  border-radius: 15px 15px;
  background-color: #ffffff;
}

  
  