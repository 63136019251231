.page {
    background: #f3f3f4ab;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.btn_voltar {
    display: flex;
    justify-content: center;
    background-color: #f3f3f4ab;
    cursor: pointer;
    border: none;
    padding: .2em;
    white-space: nowrap;
    margin-top: 3em;
    margin-left: 5em;


}

.btn_voltar a {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    text-align: center;
}

.btn_voltar svg {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    margin-right: .3em;

}


.btn_voltar:hover {
    border-bottom: 1px solid #222;

}

.form_container {
        background-color: #ffffff;
        border-top: 8px solid #140c44;
        border-radius: 15px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        /* align-content: center; */
        align-self: center;
        /* flex-wrap: wrap; */
        padding: 2em;
        padding-bottom: 4em;
        width: 1400px;
        margin-top: 4em;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}

.form_container header {
    font-size: 35px;
    font-weight: 600;
    line-height: 1.5em;
    color: #140c44;
    margin-bottom: 1em;
}

.FormGroup p {
    color: rgb(141, 141, 141);
    font-size: 16px;
    margin: .5em;
}

.label {
    color: #2596be;
    font-size: 20px;
    font-weight: 600;
    margin-top: 1.5em;
}

/* .btn_buscar {
    background-color: rgb(13 110 253 / 0.11)!important;
    
    border: none;
    border-radius: 5px;
    padding: .4em;
    margin-top: 4.1em;
    margin-left: .1em;
    width: 200px;

}

/* .btn_buscar a {
    color: #008cff!important;
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
}

.btn_buscar:hover {
    background-color: #008cff!important;
    color: #ffffff!important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.btn_buscar a:hover {
    color: #ffffff!important;
    text-decoration: none;
} */



.btn_novo {
    background-color: rgb(23 160 14 / 0.11)!important;
    color: #15ca20!important;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    padding: .4em;
    margin-top: 4.3em;
    /* margin-left: .1em; */
    width: 200px;

}

.btn_novo a {
    color: #15ca20!important;
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
}

.btn_novo:hover {
    background-color: #15a11e!important;
    color: #ffffff!important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.btn_novo a:hover {
    color: #ffffff!important;
    text-decoration: none;
}

.espaco {
    color: #ffffff;
}