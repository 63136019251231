.page {
    background: rgb(241, 246, 249);
    font-family: "Source Sans Pro", Helvetica, sans-serif;
}

.container {
    display: grid;
    margin: 0 auto;
    border: none;
    border-radius: 5px;
    background-color: #ffffff;
    width: 100%;
    max-width: 1500px;
}

.content {
    padding: .5em;
    padding-top: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.header {
    border-bottom: 1px solid #3872b98c;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: .5em;

}

.header h3 {
    font-size: 18px;
    align-self: center;
    color: #5D6D7E;
    font-weight: 600;
}

.header p {
    margin-left: 1em;
    font-size: 14px;
    font-weight: 500;
    color: #85929E;
}

.projeto {
    font-size: 22px;
    font-weight: 600;
    margin-top: 1em;
    margin-bottom: .5em;
    color: #1B2631;
}

.table_consolidado {
    font-size: 18px;
    color: #34495E;
    padding-left: 7px;
    font-weight: bold;

}

.table_consolidado_head{
    padding: .3em;
    font-size: 16px;
    font-weight: bold;
    background-color: #D6DBDF;
}

.table_consolidado_body{
    padding: .3em;
    font-size: 13px;
    font-weight: bold;
}

.table_consolidado_total{
    padding: .3em;
    font-size: 16px;
    font-weight: bold;
}

/* .Tipo_Despesa h6 {
    font-size: 16px;
    color: #182f4b;
    margin-bottom: 1em;
    font-weight: 600;
}

.Tipo_Despesa h5 {
    font-weight: 600;
    color: #F2F4F4;
    font-size: 18px;
}

.Tipo_Despesa p {
    font-size: 14px;
    color: #222;
} */

.Metas h6 {
    font-size: 16px;
    color: #182f4b;
    margin-bottom: 1em;
    font-weight: 600;
}

.Metas h5 {
    font-weight: 600;
    color: #F2F4F4;
    font-size: 18px;
}

.Metas p {
    font-size: 14px;
    color: #222;
}

.consolidado_metas {
    font-size: 18px;
    color: #34495E;
    padding-left: 7px;
    font-weight: bold;
}

.Metas table {
    margin-bottom: 2em;
}


.printMode {
    font-size: 11px;
}

.Modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Modal_header{
display: flex;
flex-direction: column-reverse;
border: none;
border-radius: 15px 15px;
background-color: #ffffff;
margin-bottom: -1em;
visibility: .5;
font-size: 18px;

}

.Modal_header img{
    padding: 2em 0;
    width: 120px;
    
    }

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    color: #2c2c2c;
    text-align: center;
    font-size: 18px;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
}

