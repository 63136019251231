@import url(' https://cdn.syncfusion.com/ej2/material.css');


.header {
    margin: 1em;
    width: 45%;
    margin: 0 auto;
    margin-top: 3%;
    margin-bottom: 3%;
}

.header p {
    margin-bottom: .3em;
    font-size: 14px;
}

.header span {
    font-weight: 600;
}

.e-grid .e-columnheader {
    background-color: #F2F3F4;
}

.e-grid .e-headercelldiv {
    margin-top: 5px;
    font-size: 14px;
    color: #34495E;
    font-weight: 500;
}

