.page {
    display: flex;
    flex-direction: column;
    background: rgb(241, 246, 249);
    min-height: 100vh;
    font-family: 'Source Sans Pro', sans-serif;
    box-sizing: border-box;
    padding-bottom: 2em;
}



.btn {
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
    border: none;
    padding: .2em;
    text-align: center;
    white-space: nowrap;
    margin-top: 3em;
    margin-left: 5em;
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;

}

.btn a {
    color: #222;
    text-decoration: none;
}

/* .btn svg {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    margin-right: .3em;

} */


.btn:hover {
    border-bottom: 1px solid #222;

}


.box_container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-width: 80%;
    max-height: fit-content;
    margin: 0 auto;
    margin-top: 2em;
    padding: 1em;
    background-color: transparent;
}


.box_execFisica {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1em;
    min-width: 30%;
    max-height: 20vh;
    min-height: fit-content;
    margin: 1em;
}

.box_execFisica h1 {
    font-size: 20px;
    color: #808B96;
    margin-bottom: 1.5em;
}

.box_execFisica p {
    font-size: 14px;
    color: #333;
    margin-bottom: .3em;
}

.grafico_execFisica {
    margin-top: -4em;
    margin-left: 2em;
    margin-right: -3em;
}

.box_execFinanceira {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1em;
    min-width: 30%;
    max-height: 20vh;
    margin: 1em;
}

.box_execFinanceira h1 {
    font-size: 20px;
    color: #808B96;
    margin-bottom: 2em;
}

.box_execFinanceira p {
    font-size: 14px;
    color: #333;
    margin-bottom: .3em;
}

.grafico_execFinanceira {
    margin-top: -4em;
    margin-left: 2em;
    margin-right: -3em;
}


.box_saldo {
    background-color: rgb(21, 97, 174);
    background-image: url(../../Componentes/img/icons8-média-2-100.png);
    background-size: 190px;
    background-repeat: no-repeat;
    background-position: left;
    border-radius: 10px;
    padding: 1em;
    min-width: 30%;
    max-height: 20vh;
    margin: 1em;
}

.box_saldo h1 {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 2em;
    font-weight: 700;
}

.box_saldo p {
    float: right;
    font-size: 20px;
    font-weight: 600;
    color: #EBEDEF;
}

.Creditos_Debitos_Container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-width: 80%;
    max-height: fit-content;
    margin: 0 auto;
    margin-bottom: 2em;
    background-color: transparent;
}

.Creditos {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1em;
    min-width: 43%;
    max-height: 40vh;

}

.Creditos h4 {
    font-size: 25px;
    color: #229954;
    font-weight: 600;
    margin-bottom: 1em;
}

.Creditos h4 svg {
    font-size: 12px;
    margin-top: -1em;
}

.Creditos_Row1 {
    padding: 1em;
    border-bottom: 1px solid #808b965e;
    font-size: 16px;
    color: #566573;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.Creditos_Row1 h6 {
    font-size: 18px;
    font-weight: 600;
    color: #1E8449;
}

.Creditos_Row1 a{
    color: #aeb3b3;
}

.Creditos_Row1 a:hover{
    color: #747474;
}

.Creditos_Row2 {
    padding: 1em;
    font-size: 18px;
    color: #333;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
}

.Creditos_Row2 h6 {
    font-size: 18px;
    font-weight: 600;
    color: #1E8449;
    margin-bottom: -1em;
}

.Debitos {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1em;
    min-width: 43%;
    max-height: 40vh;
}

.Debitos h4 {
    font-size: 25px;
    color: #C0392B;
    font-weight: 600;
    margin-bottom: 1em;
}

.Debitos h4 svg {
    font-size: 12px;
    margin-top: -1em;
}

.Debitos_Row1 {
    padding: 1em;
    border-bottom: 1px solid #808b965e;
    font-size: 16px;
    color: #566573;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.Debitos_Row1 h6 {
    font-size: 18px;
    font-weight: 600;
    color: #C0392B;
}

.Debitos_Row1 a{
    color: #aeb3b3;
}

.Debitos_Row1 a:hover{
    color: #747474;
}

.Debitos_Row2 {
    padding: 1em;
    font-size: 18px;
    color: #333;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
}

.Debitos_Row2 h6 {
    font-size: 18px;
    font-weight: 600;
    color: #B03A2E;
    margin-bottom: -1em;
}

.table_container {
    justify-content: center;
    min-width: 73%;
    max-height: fit-content;
    margin: 0 auto;
    margin-bottom: 1em;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 1em;
}

.table_container table {
margin-top: 1em;
}

.table_container h4 {
    font-size: 20px;
    color: #808B96;
    font-weight: 600;
    margin-bottom: 1em;
}

.progress_valor {
    background-color: #299cdb;
    color: transparent;
    font-size: 10px;
    border-radius: 10px;
   }

.table_container label {
    align-self: center;
    color: #5499C7;
    padding: .5em;
    margin-left: .5em;
}
.table_container select {
    border-radius: 5px;
    background-color: #fffffff3;
    outline: none;
    margin-top: .3em;
    margin: .1em;
    width: 12em;
    color: #85929E;
    border: 1px solid #74b7e460;
}

.print_btn {
    background-color: #BDC3C7;
    border-radius: 6px;
    font-weight: 600;
    outline: 0;
    border: 0;
    cursor: pointer;
    width: 2.5em;
    height: 2em;
    margin-left: .5em ; ;
    border-radius: 6px;
    font-weight: 600;
    outline: 0;
    border: 0;
    cursor: pointer;
    width: 2.5em;
    height: 2em;
    margin-left: .5em ; 
}

.print_btn svg {
    color: #626567;
    font-weight: bold;
    font-size: 20px;
}

.print_btn svg:hover {
    color: #2980B9;
    font-weight: bold;
    font-size: 20px;
}

.print_btn:hover {
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-box-shadow: 0 5px 5px rgba(0,0,0,.1);
    box-shadow: 0 5px 5px rgba(0,0,0,.1)
}

.Modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Modal_header{
display: flex;
flex-direction: column-reverse;
border: none;
border-radius: 15px 15px;
background-color: #ffffff;
margin-bottom: -1em;
visibility: .5;
font-size: 18px;

}

.Modal_header img{
    padding: 2em 0;
    width: 120px;
    
    }

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    color: #2c2c2c;
    text-align: center;
    font-size: 18px;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
}


   











