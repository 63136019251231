.page {
    display: block;
    background: #f3f3f4ab;
    min-height: 100vh;
    justify-content: center;
    font-family: 'Source Sans Pro', sans-serif;
}

.page h2 {
    color: #fafafa;
}

.container {
    background-color: #ffff;
    border-radius: 5px;
    max-width: 1250px;
    margin: 0 auto;
    padding: 1em;
}

.header {
    background-color: #fafafa;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0.3em 1em;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.header h1 {
    font-size: 20px;
    font-weight: 600;
    align-self: center;
    color: #333;
    margin-top: .3em;
}

.header label {
    margin-right: -12em;
    align-self: center;
    color: #3f82af;
}
.header select {
    border-radius: 5px;
    background-color: #fffffff3;
    outline: none;
    margin-top: .3em;
    width: 12em;
    color: #85929E;
    border: 1px solid #3f82af9a;
}

.header input {
    border: 1px solid #3f82af9a;
    border-radius: 5px;
    width: 18em;
    height: 2.3em;
    margin-top: .2em;
    color: #85929E;
}

.header input:hover {
    border: 1px solid #0090c5;
}

.header input:focus {
    border: 2px solid #0090c5;
}


.table_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffff;
    border-radius: 5px;
    max-width: 1200px;
    margin: 0 auto;
}

.table {
    width: 95%;
    align-self: center;
    border-collapse: collapse;
    border-spacing: 0;
    font-family: 'Source Sans Pro', sans-serif;
}

.table thead {

    display: table-header-group;
    vertical-align: middle;
    border-top: 0 #ffff;
    border-left: 0 #ffff;
    border-right: 0 #ffff;
    border-bottom: 2px solid #e2e2e2;
    font-weight: 600;
    font-size: 16px;
    color: #222;

}

.th_btn {
    width: 12em;
}


.table tbody {
    vertical-align: middle;
    border-top: 0 #ffff;
    border-left: 0 #ffff;
    border-right: 0 #ffff;
    border-bottom: 1px solid #e2e2e2;
    font-weight: 400;
    font-size: 12px;
    color: #222;

}



.td_btn {
    text-align: center;
}

.aprovado {
    font-weight: 600;
    color: #50cd89;
    background-color: #e8fff3;
    border-radius: 5px;
    width: fit-content;
    padding: 0 .5em;
}

.nao_aprovado {
    font-weight: 600;
    color: #f1416c;
    background-color: #fff5f8;
    border-radius: 5px;
    width: fit-content;
    padding: 0 .5em;
}

.analise {
    font-weight: 600;
    color: #FF6C00;
    background-color: #FFEFE3;
    border-radius: 5px;
    width: fit-content;
    padding: 0 .5em;
}

.btn_look {
    background-color: #EEF6FF;
    border-radius: 6px;
    font-weight: 800;
    outline: 0;
    border: 0;
    cursor: pointer;
    width: 3em;
    height: 2em;
    margin: 1em;
    margin-right: .5em;
}

.btn_look svg {
    color: #3E97FF;
    font-weight: bold;
    font-size: 20px;
}

.btn_edit {
    background-color: #ecdef5;
    border-radius: 6px;
    font-weight: 600;
    outline: 0;
    border: 0;
    cursor: pointer;
    width: 3em;
    height: 2em;
    margin: 1em;
    margin-left: .5em;
}

.btn_edit svg {
    color: #7239ea;
    font-weight: bold;
    font-size: 20px;
}

.btn_look:hover {
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
    box-shadow: 0 5px 5px rgba(0, 0, 0, .1)
}

.btn_edit:hover {
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
    box-shadow: 0 5px 5px rgba(0, 0, 0, .1)
}

.th_prazo {
    text-align: center;
}

.td_prazo {
    text-align: center;
}

.prazo {
    font-weight: 600;
    color: #e9b408;
    background-color: #fff3d2;
    border-radius: 5px;
    width: fit-content;
    margin: 0 auto;
    padding: 0 .5em;
}

.prazo_expirado {
    font-weight: 600;
    color: #e67300;
    background-color: #ffe5cc;
    border-radius: 5px;
    width: fit-content;
    margin: 0 auto;
    padding: 0 .5em;
}

.Modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Modal_header{
display: flex;
flex-direction: column-reverse;
border: none;
border-radius: 15px 15px;
background-color: #ffffff;
margin-bottom: -1em;
visibility: .5;
font-size: 18px;

}

.Modal_header img{
    padding: 2em 0;
    width: 120px;
    
    }

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    color: #2c2c2c;
    text-align: center;
    font-size: 18px;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
}