.page {
    display: block;
    background: #f3f3f4ab;
    min-height: 100vh;
    justify-content: center;
    padding-bottom: 2em;
}

.page h2 {
    color: #fafafa;
}

.btn_container {
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    padding: 2em 0;
    max-width: 50vw;
}

.create_btn {
    display: flex;
    justify-content: center;
    background-color: #2557a3;
    transition: 0.2s;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    font-family: 'Source Sans Pro', sans-serif;
    cursor: pointer;
    font-weight: 500;
    max-height: 2em;
    line-height: 2em;
    white-space: nowrap;
}

.create_btn a {
    text-align: center;
    text-decoration: none;
    color: #ffffff;
}

.create_btn svg {
    margin-left: .3em;
    margin-bottom: .3em;
}

.create_btn:hover {
    background-color: #062654;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.create_btn a:hover {
    color: #ffffff;
}

.header {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 50vw;
    margin: 0 auto;
    padding: 1em;
    border-radius: 5px 5px 0 0;
}

.header h1 {
    font-size: 22px;
    font-weight: 600;
    margin-top: .3em;
}

.input_container {
    width: 30%;
    border: rgb(247 250 252);
    background-color: rgb(247 250 252);
    border-radius: 10px;
    display: flex;
    height: 2.5em;
}

.input_container svg {
    font-size: 2.5em;
    color: #222;
    padding: .3em;
}

.input_container input {
    background-color: transparent;
    border: none;
    color: #525252;
    width: 40%;
}


.table_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffff;
    border-radius: 5px;
    max-width: 50vw;
    margin: 0 auto;
}

.table {
    width: 95%;
    align-self: center;
    border-collapse: collapse;
    border-spacing: 0;
    font-family: 'Source Sans Pro', sans-serif;
}

.table thead {

    display: table-header-group;
    vertical-align: middle;
    border-top: 0 #ffff;
    border-left: 0 #ffff;
    border-right: 0 #ffff;
    border-bottom: 2px solid #e2e2e2;
    font-weight: 600;
    font-size: 16px;
    color: #222;
}

/* .th_numero {
        margin-left: .3em;
    } */
/* 
.th_btn {
    width: 10vw;
} */


.table tbody {
    vertical-align: middle;
    border-top: 0 #ffff;
    border-left: 0 #ffff;
    border-right: 0 #ffff;
    border-bottom: 1px solid #e2e2e2;
    font-weight: 400;
    font-size: 14px;
    color: #222;
}

/* .td_numero {
        font-weight: 700;
        color: black;
    } */



.td_btn {
    text-align: right;
}


.btn_look {
    background-color: #e0ecfa;
    border-radius: 6px;
    font-weight: 600;
    outline: 0;
    border: 0;
    cursor: pointer;
    width: 3em;
    height: 2em;
    margin: 1em;
}

.btn_look svg {
    color: #3E97FF;
    font-weight: bold;
    font-size: 20px;
}


.btn_look:hover {
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
    box-shadow: 0 5px 5px rgba(0, 0, 0, .1)
}

.btn_print {
    background-color: #D6DBDF;
    border-radius: 6px;
    font-weight: 600;
    outline: 0;
    border: 0;
    cursor: pointer;
    width: 3em;
    height: 2em;
    margin: 1em;
}

.btn_print svg {
    color: #34495E;
    font-weight: bold;
    font-size: 20px;
}


.btn_print:hover {
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
    box-shadow: 0 5px 5px rgba(0, 0, 0, .1)
}


.Modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Modal_header {
    display: flex;
    flex-direction: column-reverse;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
    margin-bottom: -1em;
    visibility: .5;
    font-size: 18px;

}

.Modal_header img {
    padding: 2em 0;
    width: 120px;

}

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    color: #2c2c2c;
    text-align: center;
    font-size: 18px;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
}