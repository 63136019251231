.page {
    background: #f3f3f4ab;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.voltar {
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
    border: none;
    padding: .2em;
    white-space: nowrap;
    margin-top: 3em;
    margin-left: 5em;


}

.voltar a {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    text-align: center;
}

.voltar svg {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    margin-right: .3em;

}


.voltar:hover {
    border-bottom: 1px solid #222;

}

.form_container {
    background-color: #ffffff;
    border-top: 5px solid #2471A3;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-content: center; */
    align-self: center;
    /* flex-wrap: wrap; */
    margin: auto 0;
    padding: 2em;
    width: 65vw;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1em;
}

.header header {
    font-size: 20px;
    font-weight: 500;
    color: #626567;
    margin-bottom: 1em;
    margin-top: .3em;
}

.orcamento_btn {
    background-color: #E67E22;
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    list-style: none;
    margin: 0;
    margin-right: 1em;
    outline: none;
    padding: 8px 12px;
    position: relative;
    text-align: center;
    transition: color 100ms;
    vertical-align: baseline;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.orcamento_btn a {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
}

.orcamento_btn:hover,
.orcamento_btn:focus {
    background-color: #EB984E;
}

.enviaaf_btn {
    background-color: #738dc9;
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    list-style: none;
    margin: 0;
    margin-right: 1em;
    outline: none;
    padding: 8px 12px;
    position: relative;
    text-align: center;
    transition: color 100ms;
    vertical-align: baseline;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.enviaaf_btn a {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
}

.enviaaf_btn:hover,
.enviaaf_btn:focus {
    background-color: #5676c2;
}

.comparativo_btn {
    background-color: #aaaaaa;
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    list-style: none;
    margin: 0;
    margin-right: 1em;
    outline: none;
    padding: 8px 12px;
    position: relative;
    text-align: center;
    transition: color 100ms;
    vertical-align: baseline;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.comparativo_btn a {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
}

.comparativo_btn:hover,
.comparativo_btn:focus {
    background-color: #868686;
}

/* .form_container header {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.65rem;
    color: #626567;
    margin-bottom: 1em;
} */

.form_group_label {
    color: #2557a3;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    line-height: 1em;
}

.form_group_label2 {
    color: #2557a3;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 22px;
    line-height: 1em;
}

.form_control {

    color: #707070;
    border: 0.5px solid #2557a33f;
    border-radius: 5px;
    background-color: #fff;
    margin-bottom: 1.5em;
    margin-top: -.3em;
}

.form_control:hover {
    border: 0.5px solid #2557a3;

}

.form_control:focus {
    border: 2px solid #062654;
    outline: none;
}

.form_control_textarea {
    color: #707070;
    border: 0.5px solid #2557a33f;
    border-radius: 5px;
    background-color: #fff;
    margin-bottom: 1.5em;
    margin-top: -.3em;
}

.form_control_textarea:hover {
    border: 0.5px solid #2557a3;

}

.form_control_textarea:focus {
    border: 2px solid #062654;
    outline: none;
}

.textarea {
    color: #222;
    /* border: 1px solid #ced4da;
    border-radius: 5px; */
    background-color: transparent;
    margin-bottom: 1.5em;
    appearance: none;
    height: 12em;
    width: 100%;
    position: relative;
}

.form_label_encaminhamento {
    font-size: 18px;
    color: #3498DB;
    font-weight: 600;
}

.table {
    margin-bottom: .3em;
    border: #d1d1d1;
    font-size: 14px;
}

.table thead {
    background-color: #2471A3;
    border: #2471A3;
    color: #ffffff;
    font-weight: 600;
}

.table tbody {
    background-color: #ffffff;
    color: #2C3E50;

}

.btn_edit {
    background-color: #ecdef5;
    border-radius: 6px;
    font-weight: 600;
    outline: 0;
    border: 0;
    cursor: pointer;
    /* width: 2.5em;
    height: 2em; */
    margin-left: .5em;
}

.btn_edit svg {
    color: #7239ea;
    font-weight: bold;
    font-size: 20px;
}

.btn_edit:hover {
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
    box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
}

.btn_delete {
    background-color: #F9EBEA;
    border-radius: 6px;
    outline: 0;
    border: 0;
    cursor: pointer;
    margin-left: .5em;
}

.btn_delete svg {
    color: #f14141;
    font-weight: bold;
    font-size: 20px;
}

.btn_delete:hover {
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
    box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
}

.editForm_container {
    padding: .5em;
    margin-top: 1em;
    margin-bottom: 1em;
    border-radius: 15px;
    background-color: #f8f8f8;
}

.cancelar_btn {
    color: #ffffff;
    background-color: #dddddd;
    border: 1px solid #dddddd;
    border-radius: 5px;
    margin: 0 auto .3em;
    width: 100px;
}

.cancelar_btn:hover {
    background-color: #bbbbbb;
    border: none;
}

.salvar_btn {
    color: #ffffff;
    background-color: #241560;
    border: 1px solid #241560;
    border-radius: 5px;
    margin: 0 auto .3em;
    width: 100px;
}

.salvar_btn:hover {
    background-color: #382b6d;
    border: none;
}

.gravarDoc_btn {
    color: #424949;
    font-weight: 600;
    background-color: #E5E8E8;
    border: 1px solid #E5E8E8;
    border-radius: 5px;
    margin-top: 2.3em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 4.5vw;
}

.gravarDoc_btn span {
    margin-bottom: .1em;
    font-size: 18px;
}

.gravarDoc_btn:hover {
    cursor: pointer;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
}

.finalizar_btn {
    color: #ffffff;
    font-weight: 600;
    padding: .1em .3em;
    background-color: #2471A3;
    border: 1px solid #2471A3;
    border-radius: 5px;
    margin: 1em .5em .0em .5em;
    transition: all .3s ease-in;
}

.finalizar_btn:hover {
    background-color: #ffffff;
    color: #2471A3;
    border: none;
    text-decoration: underline;
    padding-right: 1.5em;
    background-image: url('../img/atualizar.gif');
    background-repeat: no-repeat;
    background-size: 22px;
    background-position: right;
}

.novoItem_btn {
    color: #2471A3;
    font-weight: 600;
    background-color: #D4E6F1;
    border: 1px solid #D4E6F1;
    border-radius: 5px;
    margin: 0 auto .3em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.novoItem_btn svg {
    margin: .3em;
}

.novoItem_btn:hover {
    cursor: pointer;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
}

.Modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Modal_header {
    display: flex;
    flex-direction: column-reverse;
}

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    color: #2c2c2c;
    text-align: center;
    font-size: 18px;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
    margin-bottom: 2em;
}

.Modal_body p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
    color: #808080;
    text-align: center;
    font-size: 16px;
}

.Modal_header img {
    width: 50px;
    margin: 0 auto;
}

.pt_btn {
    background-color: #FEF9E7;
    border-radius: 5px;
    border: 1px solid #FEF9E7;
    font-weight: 600;
    align-self: flex-end;
}

.pt_btn a {
    color: #F1C40F;
    text-decoration: none;
}

.pt_btn a:hover {
    text-decoration: underline;
}

.saldo_btn {
    background-color: #EBF5FB;
    border-radius: 5px;
    border: 1px solid #EBF5FB;
    font-weight: 600;
    padding: .1em .3em;
    color: #3498DB;
    text-decoration: none;
    margin-top: .5em;
}


.saldo_btn:hover {
    background-color: #3498DB;
    color: #fff;
}

.avisoSaldo {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 1em;
    width: 50vw;
    margin: 0 auto;
}

.avisoSaldo p {
    color: #ff0000;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 1em;
}

.VerAquivo_btn {
    appearance: none;
    background-color: #FAFBFC;
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
    box-sizing: border-box;
    color: #24292E;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    list-style: none;
    padding: 6px 16px;
    position: relative;
    transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    word-wrap: break-word;
}

.VerAquivo_btn:hover {
    background-color: #F3F4F6;
    text-decoration: none;
    transition-duration: 0.1s;
}

.VerAquivo_btn:disabled {
    background-color: #FAFBFC;
    border-color: rgba(27, 31, 35, 0.15);
    color: #959DA5;
    cursor: default;
}

.VerAquivo_btn:active {
    background-color: #EDEFF2;
    box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
    transition: none 0s;
}

.VerAquivo_btn:focus {
    outline: 1px transparent;
}

.VerAquivo_btn:before {
    display: none;
}

.VerAquivo_btn:-webkit-details-marker {
    display: none;
}