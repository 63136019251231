.page {
    display: block;
    background: #f3f3f4ab;
    justify-content: center;
    min-height: 100vh;
}

.voltar {
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
    border: none;
    padding: .2em;
    white-space: nowrap;
    margin-top: 3em;
    margin-left: 5em;


}

.voltar a {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    text-align: center;
}

.voltar svg {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    margin-right: .3em;

}


.voltar:hover {
    border-bottom: 1px solid #222;

}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
}

.header h1 {
    color: #2557a3;
    font-size: 2.5em;
    line-height: 1.5em;
    letter-spacing: -0.025em;
    margin: .5 0 1em 0;
    align-self: center;
}

.header p {
    font-size: 20px;
    font-weight: 600;
    color: #062654;
    align-self: center;
    margin-bottom: -.5em;
}


.container {
    max-width: 1400px;
    min-height: fit-content;
    min-height: 40vh;
    margin: 0 auto;
    margin-top: 2em;
    background-color: #fff;
    padding: 1.5rem;
    padding-bottom: 5em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;

}

.form_group {
    width: 90%;
}

.select {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin: 0 auto;

}

/* .select_lable {
    color: #2596be;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.35em;
    line-height: 1em;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5em;
    margin-top: -.3em;
} */

.form_group_label {
    color: #2596be;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.35em;
    line-height: 1em;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5em;
    margin-top: -.3em;

}

.inserir_btn {
    font-weight: 600;
    color: #e9b408;
    background-color: #fff3d2;
    border-radius: 5px;
    border: none;
    color: #e9b408;
    padding: 0.4em;
    font-size: 16px;
    line-height: 1.5;
    max-width: 300px;
    margin: 0 auto;
    margin-top: 1em;
    margin-bottom: 1em;

}

.inserir_btn svg {
    margin-left: .3em;
    margin-bottom: .3em;
}

.inserir_btn:hover {
    background-color: #e9b408;
    color: #ffffff;
}

.form_control {

    color: #707070;
    border: 0.5px solid #2557a33f;
    border-radius: 5px;
    background-color: transparent;
    margin-bottom: 1.5em;
    margin-top: -.3em;
}

.form_control option strong {

    font-weight: 600;
    color: #9b2727;

}

.form_control:hover {
    border: 0.5px solid #2557a3;

}

.form_control:focus {
    border: 2px solid #062654;
    outline: none;
}

.table {
    width: 90%;
    margin: 0 auto;

}

.table th {
    color: #010913;
    font-size: 18px;
    font-weight: 600;
    background-color: #98d0eb83;
}

.th_btn {
    width: 5em;
}

.btn_delete {
    color: #f14141;
    background-color: transparent;
    border: none;
    font-size: 25px;
}

.btn_delete:hover {
    color: #9b2727;
}

.btn_edit {
    background-color: #ecdef5;
    border-radius: 6px;
    font-weight: 600;
    outline: 0;
    border: 0;
    cursor: pointer;
    width: 2.5em;
    height: 2em;
    margin-left: .5em;
}

.btn_edit svg {
    color: #7239ea;
    font-weight: bold;
    font-size: 20px;
}

.btn_edit:hover {
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
    box-shadow: 0 5px 5px rgba(0, 0, 0, .1)
}

.aviso_delete {
    margin-top: 2em;
}

.aviso_delete h5 {
    color: #222;
    font-weight: 600;
    font-size: 18px;
}

.aviso_delete p {
    color: #be2323;
}

.confirmar_btn {
    color: #ffffff;
    background-color: #0679F3;
    border: 1px solid #0679F3;
    border-radius: 5px;
    margin: .5em;
    width: 100px;
}

.confirmar_btn:hover {
    background-color: #0663c7;
    border: none;
}

.cancelar_btn {
    color: #ffffff;
    background-color: #C1C1C1;
    border: 1px solid #C1C1C1;
    border-radius: 5px;
    margin: .5em;
    width: 100px;
}

.cancelar_btn:hover {
    background-color: #999999;
    border: none;
}


.Modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Modal_header {
    display: flex;
    flex-direction: column-reverse;
}

.Modal_header img {
    padding: 2em 0;
    width: 150px;
}

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.Modal_body p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
    color: #808080;
    text-align: center;
    font-size: 16px;
}