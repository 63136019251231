.index {
    background: rgb(241, 246, 249);
    min-height: 100vh;
}

.page {
    display: flex;
    align-items: center;
    justify-content: center;

}

.box_container {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    max-width: 80%;
    padding: 2em 0;
}

.box_welcome {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgb(21, 58, 84);
    min-height: 15vh;
    min-width: 28%;
    padding: 1em;
    border-radius: 10px;
    margin-right: 1em;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.col1 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: .5em 0 1em 0;
}

.col1 h5 {
    color: #F8F9F9;
}

.col1 h3 {
    color: #ffffff;
    font-size: 18px;
}

.col2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.box_tbd {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    min-height: 15vh;
    min-width: 33%;
    padding: 1em;
    border-radius: 10px;
    margin-left: 1em;
    margin-right: 1em;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.box_tbd h2 {
    color: #7B7D7D;
}

.box_print {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #5499C7;
    min-height: 15vh;
    min-width: 35%;
    padding: 1em;
    border-radius: 10px;
    margin-left: 1em;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.box_print h4 {
    color: #ffffff;
    text-align: center;
}

.box_area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #5499C7;
    min-height: 15vh;
    min-width: 35%;
    padding: 1em;
    border-radius: 10px;
    margin-left: 1em;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: relative;
}

.box_area h4 {
    color: #ffffff;
}

.box_area p {
    color: #ffffff;
}

.box_area a {
    color: #212F3C;
    font-weight: 600;
    text-decoration: none;
}

.box_area a svg {
    margin-bottom: .1em;
}

.box_area a:hover {
    color: #154360;
    text-decoration: underline;
    cursor: pointer;
    transition: ease-in-out 0.3s;
}



.form_group_label {
    color: #EBF5FB;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1em;
    line-height: 1em;
    font-size: 16px;
    font-weight: 500;
    margin-top: -.5em;
}

.form_control {
    border-radius: 3px;
    border: 0;
    color: #7B7D7D;
    padding: .3em .5em;
    font-size: 14px;
    width: 10vw;
}

.btn_print {
    appearance: none;
    background-color: #094C76;
    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    box-sizing: border-box;
    color: #D6EAF8;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 6px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
}

.btn_print:focus:not(:focus-visible):not(.focus-visible) {
    box-shadow: none;
    outline: none;
}

.btn_print:hover {
    background-color: #154360;
}

.btn_print:focus {
    box-shadow: #052D46;
    outline: none;
}

/* .btn_print:disabled {
    background-color: #94d3a2;
    border-color: rgba(27, 31, 35, .1);
    color: rgba(255, 255, 255, .8);
    cursor: default;
}

.btn_print:active {
    background-color: #298e46;
    box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
} */

/* .div_print {
    text-align: center;
    border-radius: 10px;
    background-color: #fff;
    padding: 1em;
    margin-right: 1em;
} */

.table_container {
    max-width: 80%;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1em;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 2em;

}

/* .table_container Table {
    max-width: 80%;
    margin: 0 auto;
} */

/* .table_container Table a {
    text-decoration: none;
    color: #000000;
}

.table_container Table a:hover {
    text-decoration: underline;
    color: #2584c4;
    font-weight: 600;
} */
.table_container h3 {
    color: #7B7D7D;
    padding-bottom: 1em;
    font-size: 18px;
}

.table_container h3 span {
    padding: .3em;
    font-size: 24px;
}

.btn_solic {
    background-color: transparent;
    border: none;
    border-radius: 5px;
    transition: all 0.3s ease-in;

}

.btn_solic a {
    font-size: 20px;
    color: #bebebe;
    padding: .3em;
}

.btn_solic a:hover {
    color: #21618C;
    text-decoration: none;
    font-weight: 600;

}

/* .btn_solic:hover {
    cursor: pointer;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    background-color: #21618C;
} */

.btn_solic:active {
    /* Estilos quando o botão é clicado */
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background-color: #154360;
}

.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: #000;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: #000;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.Modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Modal_header {
    display: flex;
    flex-direction: column-reverse;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
    margin-bottom: -1em;
    visibility: .5;
    font-size: 18px;

}

.Modal_header img {
    padding: 2em 0;
    width: 120px;

}

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    color: #2c2c2c;
    text-align: center;
    font-size: 18px;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
}

.relatorio {
    background-color: #D6EAF8;
    padding: .5em;
    min-width: 12vw;
    border: none;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    transition: background-color 0.3s;
    color: #2E86C1;
    font-size: 16px;
}

.relatorio:hover {
    background-color: #2E86C1;
    font-size: 18px;
    color: #fff;
}

.relatorio a {
    text-decoration: none;
    font-size: 16px;
    color: inherit;
    /* Usa a cor do texto do elemento pai (.relatorio) */
    font-weight: 600;
}

.relatorio a:hover {
    text-decoration: none;
    color: #fff;

}

.relatorio_buttons {
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    justify-content: center;
    padding: 1em;
    width: 80%;
    margin: 0 auto;

}

.relatorio_buttons h1 {
    font-size: 18px;
    font-weight: 600;
    color: #566573;

}

.add_buttons {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 1em;
    width: 50%;
    border-radius: 5px;
    margin-left: 1em;
    margin-right: 1em;
}

.add_buttons h1 {
    font-size: 18px;
    font-weight: 600;
    color: #566573;
    margin: 0 auto;
}

.btn_escolherProjeto {
    border: none;
    background-color: transparent;
    color: #7B7D7D;
    font-size: 20px;
}

.btn_escolherProjeto:hover {
    border: none;
    background-color: transparent;
    color: #3498DB;
    font-weight: 600;
}

/* .index_container {
    padding: 1em 1em;
    align-items: center;
    justify-content: center;
    display: flex;
    
}

.index_button,
.index_button_twoline {
    background-color: #ffffff;
    padding: 15px;
    border: none;
    border-radius: 5px;
    margin: 1rem;
    margin-bottom: -1rem;
    margin-right: 1.5em;
    width: 22rem;
    height: 6rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out

}

.index_button h2,
.index_button_twoline h2 {
    color: #000000;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    line-height: 2em;
    font-weight: 600;
    flex-wrap: wrap;
    flex-shrink: 1;
    flex-direction: column;
   
}

.index_button p,
.index_button_twoline p {
    color: #b8b8b8;
    font-size: 14px;
    font-weight: 400;
    line-height: 1em;
}

.index_button a,
.index_button_twoline a {
    text-decoration: none;  
    cursor: pointer;   
    display: flex;
    align-items: center;
    justify-content: right;
    margin-left: 0.5rem;
    margin-bottom: 1.5em;
}

.index_button:hover,
.index_button_twoline:hover {
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-box-shadow: 0 5px 5px rgba(0,0,0,.1);
    box-shadow: 0 5px 5px rgba(0,0,0,.1);


}

.icon {
    display: inline-block;
    float: none;
    width: 60px;
}

.card_solic {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    height: auto;
    width: 800px;
    padding: 2em;
    border-radius: 10px;
    margin-top: 2rem;
    margin-bottom: 2em ;
}

.card_solic h4 {
    color: #4e4e4e;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    margin-bottom: 1.5rem;
    font-family: 'Source Sans Pro', sans-serif;
}

.form_solic input {
    margin: 0.5em 0;
    padding: 0.5em 1em;
    border-radius: 5px;
    border: 1px solid #e4e6ef;
    background-color: #ffffff;
    width: 300px;
    height: 40px;

}

.form_solic input::placeholder {
    color: #b7b9c2;
    font-size: 16px;
}

.form_solic svg {
    color: #808080;
    margin-right: .3em;
}

.btn {
    display: flex;
    background-color: #3e97ff;
    border: none;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    font-size: 14px;
    font-family: 'Source Sans Pro', sans-serif;
    margin: 0.5rem;
    margin-top: 1rem;
  }

.btn a {
    text-decoration: none;
    transition: 0.2s;
    cursor: pointer;
    color: #fff;
    padding: 0.5em;
    border: none;
    font-size: 14px;
}

.btn:hover {
    background-color: #4784c9;
    box-shadow: 0px 8px 16px 4px rgba(133, 133, 133, 0.08);

}

.link:hover {
    text-decoration: none;
    color: #fff;
} */