.page {
    display: flex;
    flex-direction: column;
    background: rgb(241, 246, 249);
    min-height: 100vh;
    font-family: 'Source Sans Pro', sans-serif;
    box-sizing: border-box;
    padding-bottom: 2em;

}

.btn {
    display: flex;
    justify-content: center;
    background-color: #2557a3;
    color: #ffffff;
    text-decoration: none;
    transition: 0.2s;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    padding: .2em;
    float: right;
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    max-height: 2em;
    line-height: 2em;
    padding: 0 2em;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    margin-top: 2em;

}

.btn svg {
    margin-left: .4em;
    margin-bottom: .2em;
}

.btn:hover {
    background-color: #062654;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}

.voltar {
    background-color: transparent;
    display: flex;
    justify-content: center;
    cursor: pointer;
    border: none;
    padding: .2em;
    white-space: nowrap;
    margin-top: 3em;
    margin-left: 5em;
}

.voltar a {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    text-align: center;
}

.voltar svg {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    margin-right: .3em;

}


.voltar:hover {
    border-bottom: 1px solid #222;

}


.box_container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-width: 60vw;
    max-width: 60vw;
    max-height: fit-content;
    margin: 0 auto;
    margin-top: 2em;
    margin-bottom: 1em;
    background-color: transparent;
}

.box_container2 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-height: fit-content;
    margin: 0 auto;
    margin-top: 2em;
    margin-bottom: 1em;

    width: 98%;
}

.boxlista {
    overflow-y: auto;
    /* Adicione esta linha para adicionar a barra de rolagem vertical */
    max-height: 750px;
    background-color: #fff;
    padding: .5em;
    border-radius: 10px;

    /* Ajuste a altura máxima conforme necessário */
}

.boxarquivo {
    overflow-y: auto;
    /* Adicione esta linha para adicionar a barra de rolagem vertical */
    /*max-height: 750px; */
    /* Ajuste a altura máxima conforme necessário */
}

.tablebox {

    /* Adicione esta linha para adicionar a barra de rolagem vertical */
    /*max-height: 750px; */
    /* Ajuste a altura máxima conforme necessário */
    width: 95%;
}

.customCol {
    width: 90%;
}

.customCol2 {
    width: 10%;
}

.box_processo {
    display: flex;
    flex-direction: column;
    background-color: #1b2850;
    border-radius: 10px;
    padding: 1em;
    min-width: 100%;
    max-height: auto;
    margin: 0 auto;
}

.box_processo h1 {
    font-size: 18px;
    color: #F7F9F9;
    margin-bottom: 2em;
}

.box_processo h3 {
    font-size: 16px;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 1em;
}

.box_processo h2 {
    font-size: 14px;
    color: #F7F9F9;
    font-weight: 600;
    margin-bottom: 1em;
}

.box_processo span {
    font-size: 26px;
    color: #ffffff;
}

/* .box_processo:hover span {
    font-size: 28px;
    color: #ffffff;
    transition: all .1s ease;
} */

.box_processo p {
    font-size: 16px;
    color: #F7F9F9;
}


/* .box_objetivo {
    display: flex;
    flex-direction: column;
    background-color: #ff9f43;
    border-radius: 10px;
    padding: 1em;
    min-width: 30%;
    max-height: auto;
    margin: 1em;
}

.box_objetivo h1 {
    font-size: 20px;
    color: #F7F9F9;
    font-weight: 600;
}

.box_objetivo p {
    font-size: 16px;
    color: #ffffff;
    margin-bottom: .3em;
}

.box_objetivo span {
    font-size: 26px;
    color: #ffffff;
}

.box_objetivo:hover span {
    font-size: 28px;
    color: #ffffff;
    transition: all .5s ease;
}

.box_origem {
    display: flex;
    flex-direction: column;
    background-color: #00cfe8;
    border-radius: 10px;
    padding: 1em;
    min-width: 30%;
    max-height: auto;
    margin: 1em;
}

.box_origem h1 {
    font-size: 24px;
    color: #F7F9F9;
    margin-bottom: 2em;
    font-weight: 600;
}

.box_origem p {
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
}

.box_origem span {
    float: right;
    font-size: 26px;
    color: #ffffff;
}

.box_origem:hover span {
    font-size: 28px;
    color: #ffffff;
    transition: all .5s ease;
}
 */



.btn_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 85%;
    max-height: fit-content;
    margin: 0 auto;
    margin-top: 2em;
    background-color: transparent;
    border-radius: 10px;
}

.box_historico {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1em;
    min-width: 60vw;
}

.box_historico h4 {
    font-weight: 500;
    font-size: 16px;
    color: #154360;
    text-align: center;
    padding-bottom: .5em;
    font-weight: bold;
}

.box_Detalhes p {
    color: #707070;
    font-size: 15px;
    padding: .5em;
}

.box_Detalhes {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1em;
    min-width: 60vw;
}

.box_Detalhes h4 {
    font-weight: 500;
    font-size: 16px;
    color: #154360;
    text-align: center;
    padding-bottom: .5em;
    font-weight: bold;
}

.box_Arquivos p {
    color: #707070;
    font-size: 15px;
    padding: .5em;
}


.box_Arquivos {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1em;
    min-width: 58vw;
}

.box_Arquivos h4 {
    font-weight: 500;
    font-size: 16px;
    color: #154360;
    text-align: center;
    padding-bottom: .5em;
    font-weight: bold;
}


.table {
    margin-bottom: .3em;
    border: #d1d1d1;
}

.table thead {
    background-color: #2C3E50;
    border: #2C3E50;
    color: #ffffff;
    font-weight: 600;
    font-size: 12px;
}

.table tbody {
    background-color: #ffffff;
    color: #2C3E50;
    font-size: 10px;

}

.box_arquivos {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1em;
    min-width: 60vw;
}

.box_arquivos h4 {
    font-weight: 500;
    font-size: 16px;
    color: #154360;
    text-align: center;
    padding-bottom: .5em;
    font-weight: bold;
}

.mapList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    min-width: 55vw;
    min-height: 50vh;
    margin: 0 auto;
    margin-top: 1em;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1em 0;
}

.Card {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.5px dashed #d1d1d1;
    background-color: #ffffff;
    margin: 1em;
    width: 90%;
    height: 135vh;
    font-family: 'Source Sans Pro', sans-serif;
    color: #ad0000;
    font-weight: 600;
    font-size: 20px;
}

.Card a {
    font-family: 'Source Sans Pro', sans-serif;
    color: #636363;
    font-weight: 600;
    font-size: 14px;
}

/* .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 2em;

}

.header h2 {
    font-size: 25px;
    font-weight: 600;
    color: #5a5a5a;
}

.header p {
    font-size: 18px;
    color: #a5a5a5;
} */

/* .edit_buttons {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 1em;
    width: 50%;
    border-radius: 5px;
    margin-right: 1em;
}

.edit_buttons h1 {
    font-size: 18px;
    font-weight: 600;
    color: #566573;
    margin: 0 auto;
}

.edit2 {
    background: #f6f7f8;
    padding: .5em;
    min-width: 200px;
    border: none;
    margin: 1em;
    margin-right: 1.5em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    text-decoration: none;
    color: #919ca7;
    font-weight: 600;

}

.Card {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.5px dashed #d1d1d1;
    background-color: #ffffff;
    margin: .5em;
    width: 45%;
    height: 55vh;
    font-family: 'Source Sans Pro', sans-serif;
    color: #ad0000;
    font-weight: 600;
    font-size: 20px;
}

.Card a {
    font-family: 'Source Sans Pro', sans-serif;
    color: #636363;
    font-weight: 600;
    font-size: 14px;
}

.edit {
    background: #f6f7f8;
    padding: .5em;
    min-width: 200px;
    border: none;
    margin: 1em;
    margin-right: 1.5em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

}

.edit:hover {
    background-color: #edf1f5;
    font-size: 18px;
}

.edit a {
    text-decoration: none;
    color: #919ca7;
    font-weight: 600;
}

.edit a:hover {
    text-decoration: none;
    color: #58b8e4;

}

.add2 {
    background: #f6f7f8;
    padding: .5em;
    min-width: 200px;
    border: none;
    margin: 1em;
    margin-right: 1.5em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    text-decoration: none;
    color: #919ca7;
    font-weight: 600;

}

.add_buttons {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 1em;
    width: 50%;
    border-radius: 5px;
    margin-left: 1em;
    margin-right: 1em;
}

.add_buttons h1 {
    font-size: 18px;
    font-weight: 600;
    color: #566573;
    margin: 0 auto;
}

.add {
    background: #f6f7f8;
    padding: .5em;
    min-width: 200px;
    border: none;
    margin: 1em;
    margin-right: 1.5em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.add:hover {
    background-color: #edf1f5;
    font-size: 18px;
}

.add a {
    text-decoration: none;
    color: #919ca7;
    font-weight: 600;
}

.add a:hover {
    text-decoration: none;
    color: #52BE80;

}

.relatorio_buttons {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 1em;
    width: 50%;
    border-radius: 5px;
    margin-left: 1em;

}

.relatorio_buttons h1 {
    font-size: 18px;
    font-weight: 600;
    color: #566573;
    margin: 0 auto;
}

.relatorio2 {
    background: #f6f7f8;
    padding: .5em;
    min-width: 200px;
    border: none;
    margin: 1em;
    margin-right: 1.5em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    text-decoration: none;
    color: #919ca7;
    font-weight: 600;

}

.relatorio {
    background: #f6f7f8;
    padding: .5em;
    min-width: 200px;
    border: none;
    margin: 1em;
    margin-right: 1.5em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.relatorio:hover {
    background-color: #edf1f5;
    font-size: 18px;
}

.relatorio a {
    text-decoration: none;
    font-size: 16px;
    color: #919ca7;
    font-weight: 600;
}

.relatorio a:hover {
    text-decoration: none;
    color: #f8cc1c;

} */

.form {
    min-width: 60vw;
    max-width: 60vw;
    margin: 0 auto;
}

.form_group {
    margin-bottom: 2em;
    width: 95%;
}

.form_group h5 {
    color: #062654;
    margin-bottom: 1em;
    text-decoration: underline;
}

.form_group_textarea {
    margin-bottom: 2em;

}

.form_group_label {
    color: #386cb9;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.35em;
    line-height: 1em;
    font-size: 18px;
    line-height: 1.5em;
    margin-top: -.3em;

}

.form_control,
.form_control_textarea,
.select_input {

    color: #707070;
    border: 0.5px solid #2557a33f;
    border-radius: 5px;
    background-color: transparent;
    margin-bottom: 1.5em;
    margin-top: -.3em;
}

.form_control_textarea {
    height: 80px;
    width: 420px;
    color: #000000;
    font-size: 14px;
}

.form_control:hover,
.form_control_textarea:hover,
.select_input:hover {
    border: 0.5px solid #2557a3;

}

.form_control:focus,
.form_control_textarea:focus,
.select_input:focus {
    border: 2px solid #062654;
    outline: none;
}

.actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.checkbox {
    margin: 1em;
    color: #062654;
    float: inline-start;
    width: 1em;
    -webkit-transform: scale(1.5);
}

/* para select único */
.select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    margin: 0 auto;

}

.select_lable {
    margin-bottom: 1.5em;
    color: #386cb9;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.35em;
    line-height: 1em;
    font-size: 18px;
    line-height: 1.5em;
    margin-top: -.3em;
}


.btn_send {
    background-color: #fff;
    border: 0 solid #e2e8f0;
    border-radius: 1.5rem;
    box-sizing: border-box;
    color: #0d172a;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    line-height: 0.3;
    padding: .5em 1em;
    text-align: center;
    text-decoration: none #0d172a solid;
    text-decoration-thickness: auto;
    transition: all .1s cubic-bezier(.4, 0, .2, 1);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-right: 1em;
}

.btn_send:hover {
    background-color: #1e293b;
    color: #fff;
}

.btn_send svg {
    font-size: 18px;
    margin-left: .3em;
    margin-bottom: .1em;
}

.btn_ativo {
    background-color: #1e293b;
    color: #ffffff;
}


/* .btn_send {
    background-color: #ffffff;
    cursor: pointer;
    border: 1px solid #2471A3;
    border-radius: 8px;
    white-space: nowrap;
    color: #2471A3;
    font-size: 16px;
    font-weight: 600;
    margin-right: 1em;
}

.btn_send svg {
    font-size: 18px;
    margin-left: .3em;
    margin-bottom: .1em;
}

.btn_send:hover {
    background-color: #2471A3;
    color: #ffffff;
} */


.btn_excluir {
    background-color: #fff;
    border: 0 solid #e2e8f0;
    border-radius: 1.5rem;
    box-sizing: border-box;
    color: #E74C3C;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    line-height: 0.3;
    padding: .5em 1em;
    text-align: center;
    text-decoration: none #E74C3C solid;
    text-decoration-thickness: auto;
    transition: all .1s cubic-bezier(.4, 0, .2, 1);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-right: 1em;
}

.btn_excluir:hover {
    background-color: #E74C3C;
    color: #fff;
}

.btn_excluir svg {
    font-size: 18px;
    margin-left: .3em;
    margin-bottom: .1em;
}

.btn_ativoExcluir {
    background-color: #E74C3C;
    color: #ffffff;
}

/* 
.btn_excluir {
    background-color: #ffffff;
    cursor: pointer;
    border: 1px solid #E74C3C;
    border-radius: 8px;
    white-space: nowrap;
    color: #E74C3C;
    font-size: 16px;
    font-weight: 600;
    margin-right: 1em;
}

.btn_excluir:hover {
    background-color: #E74C3C;
    color: #ffffff;
}



.btn_ativoExcluir {
    background-color: #E74C3C;
    color: #ffffff;
} */

.btn_print {
    background-color: #D6DBDF;
    border-radius: 6px;
    font-weight: 600;
    outline: 0;
    border: 0;
    cursor: pointer;
    margin-right: 2em;
    padding: 0 .5em;
}

.btn_print svg {
    color: #34495E;
    font-weight: bold;
    font-size: 20px;
}


.btn_print:hover {
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
    box-shadow: 0 5px 5px rgba(0, 0, 0, .1)
}

.btn_atualizar {
    background-color: #0095ff;
    border: 1px solid transparent;
    border-radius: 5px;
    box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", "Liberation Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.15385;
    margin-top: 1em;
    outline: none;
    padding: 5px .8em;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    white-space: nowrap;
}

.btn_atualizar svg {
    font-size: 18px;
    margin-left: .3em;
    margin-bottom: .2em;
    color: #fff;
}

.btn_atualizar:hover,
.btn_atualizar:focus {
    background-color: #07c;
}

.btn_atualizar:focus {
    box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
}

.btn_atualizar:active {
    background-color: #0064bd;
    box-shadow: none;
}

.btn_aprov {
    background-color: #d7bbbb92;
    border: 0 solid #e2e8f0;
    border-radius: 1.5rem;
    box-sizing: border-box;
    color: #0d172a;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    line-height: 0.3;
    padding: .5em 1em;
    text-align: center;
    text-decoration: none #0d172a solid;
    text-decoration-thickness: auto;
    transition: all .1s cubic-bezier(.4, 0, .2, 1);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-right: 1em;
}

.btn_aprov:hover {
    background-color: #2471A3;
    color: #fff;
}

.btn_aprov svg {
    font-size: 18px;
    margin-left: .3em;
    margin-bottom: .1em;
}

.btn_ativo2 {
    background-color: #2471A3;
    color: #ffffff;
}


/* .btn_atualizar {
    background-color: #DC7633;
    cursor: pointer;
    border: none;
    border-radius: 8px;
    white-space: nowrap;
    color: #ffffff;
    font-size: 16px;
    margin-right: 2em;
    padding: .3em .5em;
}

.btn_atualizar svg {
    font-size: 18px;
    margin-left: .3em;
    margin-bottom: .2em;
    color: #fff;
}

.btn_atualizar:hover {
    background-color: #D35400;
    transition: all .2s ease-in-out;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
} */

.inserir_btn {
    background-color: #f39c12;
    border-color: #f39c12;
    color: #fff;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    border-radius: 3px;
    min-width: fit-content;
    margin-bottom: 2.1em;
    margin-left: 1em;
}

.inserir_btn>svg {
    fill: #fff;
    gap: .2em;
    margin-bottom: .3em;
}

.gerenciar {
    margin-top: 1em;
    margin-bottom: 1em;
}

/* .title_encaminhamento {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #ffffff;
    width: 100%;
    border-bottom: 1px solid rgb(206, 206, 206);
    margin-bottom: 1em;
    margin-top: -.3em;
    padding: .5em;
} */

.box_gerenciar h4 {
    font-weight: 500;
    font-size: 16px;
    color: #154360;
    text-align: center;
    padding-bottom: .5em;
    font-weight: bold;
}

.box_gerenciar {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: none;
    border-radius: 10px;
    min-width: 58vw;
    min-height: 20vh;
    padding: 1em;
    margin-top: 1em;
    margin: 0 auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0;
}

.box_gerenciar li {
    list-style: none;
    border-bottom: 1px solid #e2e2e2;
    font-size: 18px;
    font-weight: 500;
    color: #707070;
}

.form_label_gerenciar {
    font-size: 18px;
    color: #3498DB;
    font-weight: 600;
}

.VerAquivo_btn {
    appearance: none;
    background-color: #FAFBFC;
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
    box-sizing: border-box;
    color: #24292E;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    list-style: none;
    padding: 4px 14px;
    position: relative;
    transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    word-wrap: break-word;
}

.VerAquivo_btn:hover {
    background-color: #F3F4F6;
    text-decoration: none;
    transition-duration: 0.1s;
}

.VerAquivo_btn:disabled {
    background-color: #FAFBFC;
    border-color: rgba(27, 31, 35, 0.15);
    color: #959DA5;
    cursor: default;
}

.VerAquivo_btn:active {
    background-color: #EDEFF2;
    box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
    transition: none 0s;
}

.VerAquivo_btn:focus {
    outline: 1px transparent;
}

.VerAquivo_btn:before {
    display: none;
}

.VerAquivo_btn:-webkit-details-marker {
    display: none;
}

/* .btn_encaminhamento {
    background-color: #e0ecfa;
    cursor: pointer;
    border: none;
    border-radius: 8px;
    white-space: nowrap;
    color: #2471A3;
    font-size: 16px;
    margin-right: 2em;
    padding: .3em;
}

.btn_encaminhamento svg {
    font-size: 18px;
    margin-left: .3em;
    margin-bottom: .2em;
}

.btn_encaminhamento:hover {
    background-color: #2471A3;
    color: #fff;
    transition: all .2s ease-in-out;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
} */

/* .btn_atualizar {
    background-color: #1B4F72;
    cursor: pointer;
    border: none;
    border-radius: 8px;
    white-space: nowrap;
    color: #ffffff;
    font-size: 16px;
    margin-right: 2em;
    padding: .3em .5em;
}

.btn_atualizar svg {
    font-size: 18px;
    margin-left: .3em;
    margin-bottom: .2em;
    color: #fff;
}

.btn_atualizar:hover {
    background-color: #154360;
    transition: all .2s ease-in-out;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
} */


/* .form_check {
    margin-top: .3em;
    margin-left: 2em;
    align-self: center;
    float: inline-start;
    width: 1em;
    -webkit-transform: scale(1.5);
} */

.Modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Modal_header {
    display: flex;
    flex-direction: column-reverse;
    color: #4b4b4b;
}

.Modal_header svg {
    font-size: 40px;
    color: #4b4b4b;
}

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #808080;
    text-align: center;
    font-size: 18px;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;

}

.Modal_body p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;
    color: #5e5e5e;
    text-align: center;
    font-size: 16px;
}

.Modal_body img {
    width: 40px;
    margin: 0 auto;
}

.Modal_body h6 {

    font-weight: 400;
    color: #ad0101;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    padding: 1em;
    margin-bottom: 1em;
}


.label_modal {
    color: #1b4f72;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 1em;
    margin-top: -.3em;

}

.btn_salvar {
    color: #ffffff;
    background-color: #0679F3;
    border: 1px solid #0679F3;
    border-radius: 5px;
    margin: .5em;
    width: 100px;
}

.btn_salvar:hover {
    background-color: #0663c7;
    border: none;
}

.btn_cancelar {
    color: #ffffff;
    background-color: #C1C1C1;
    border: 1px solid #C1C1C1;
    border-radius: 5px;
    margin: .5em;
    width: 100px;
}

.btn_cancelar:hover {
    background-color: #999999;
    border: none;
}




/* .timeline {
    list-style: circle;
    padding: 0;
    position: relative;
}

.timeline li {
    position: relative;
}

.timeline li::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    background-color: #ccc;
    transform: translateX(-50%);
    z-index: -1;
}

.timeline li:last-child::before {
    height: 50%;
}

.timelineContent {
    padding: 10px;
    margin-top: 10px;
}

.timelineCard {
    margin-top: 10px;
} */