
.btn_container {

    position: sticky;
    margin-right: 2em;
    margin-bottom: .3em;

}
.btn {
    display: flex;
    background-color: #EAF2F8;
    color: #103650;
    text-decoration: none;
    transition: 0.2s;
    border: none;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    padding: .2em ;


    }

.btn:hover {
    background-color: #D4E6F1;
    border: none;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}



.icon {
    display: flex;
    flex-wrap: wrap;
    color: #103650;
    margin-top: 4px;
    margin-bottom: 2px;
    margin-left: 10px;
}
