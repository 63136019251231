.voltar {
    background-color: transparent;
    display: flex;
    justify-content: center;
    cursor: pointer;
    border: none;
    padding: .2em;
    white-space: nowrap;
    margin-top: 3em;
    margin-left: 5em;
}

.voltar a {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    text-align: center;
}

.voltar svg {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    margin-right: .3em;

}


.voltar:hover {
    border-bottom: 1px solid #222;

}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
}

.header h1 {
    color: #2557a3;
    font-size: 2.5em;
    line-height: 1.5em;
    letter-spacing: -0.025em;
    margin: .5 0 1em 0;
    align-self: center;
}

.header p {
    color: #4b4b4b;
    align-self: center;
}


.container {
    max-width: 1400px;
    height: auto;
    margin: 0 auto;
    margin-top: .5em;
    background-color: #fff;
    padding: 1.5rem;
    padding-bottom: 2em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}

/* .container_header {
    background-color: #062654;
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 20px;
    border-radius: 5px 5px 0px 0px;
    padding: .5em 1.5em;
    margin: 0 auto;
    margin-top: 1.5em;
    max-width: 1400px;
} */

.form {
    max-width: 1100px;
    margin: 0 auto;
}

.form_group {
    margin-bottom: 2em;

}

.form_group h5 {
    color: #062654;
    margin-bottom: 1em;
    text-decoration: underline;
}

.form_group_textarea {
    margin-bottom: 2em;

}

.form_group_label {
    color: #386cb9;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.35em;
    line-height: 1em;
    font-size: 18px;
    line-height: 1.5em;
    margin-top: -.3em;

}

.form_control,
.form_control_textarea,
.select_input {

    color: #707070;
    border: 0.5px solid #2557a33f;
    border-radius: 5px;
    background-color: transparent;
    margin-bottom: 1.5em;
    margin-top: -.3em;
}

.form_control_textarea {
    height: 80%;
    width: 100%;
    color: #000000;
}

.form_control_Check {
    border-radius: 0px;
    margin-bottom: 1.5em;
    margin-top: -.3em;

}

.form_control:hover,
.form_control_textarea:hover,
.select_input:hover {
    border: 0.5px solid #2557a3;

}

.form_control:focus,
.form_control_textarea:focus,
.select_input:focus {
    border: 2px solid #062654;
    outline: none;
}

.actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.checkbox {
    margin: 1em;
    color: #062654;
    float: inline-start;
    width: 1em;
    -webkit-transform: scale(1.5);
}

/* para select único */
/* .select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    margin: 0 auto;

}

.select_lable {
    margin-bottom: 1.5em;
    color: #386cb9;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.35em;
    line-height: 1em;
    font-size: 18px;
    line-height: 1.5em;
    margin-top: -.3em;
} */

/* .inserir_btn {
    background-color: #f39c12;
    border-color: #f39c12;
    color: #fff;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    border-radius: 3px;
    min-width: fit-content;
    margin-bottom: 2.1em;
    margin-left: 1em;
}

.inserir_btn>svg {
    fill: #fff;
    gap: .2em;
    margin-bottom: .3em;
} */

.btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #2557a3;
    color: #ffffff;
    text-decoration: none;
    transition: 0.2s;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    line-height: 2em;
    padding: 0 2em;
    white-space: nowrap;
    width: 10vw;
    margin: 0 auto;
}

.btn svg {
    margin-left: .4em;
    margin-top: .5em;
}

.btn:hover {
    background-color: #062654;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}

.Modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Modal_header {
    display: flex;
    flex-direction: column-reverse;
    color: #4b4b4b;
}

.Modal_header svg {
    font-size: 40px;
    color: #df2f2fb4;
}

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #808080;
    text-align: center;
    font-size: 18px;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
}

.Modal_body p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;
    color: #5e5e5e;
    text-align: center;
    font-size: 16px;
}

.Modal_body img {
    width: 40px;
    margin: 0 auto;
}

.Modal_body h6 {

    font-weight: 400;
    color: #ad0101;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    padding: 1em;
    margin-bottom: 1em;
}

/* 
.label_modal {
    color: #1b4f72;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    line-height: 1em;
    margin-top: -.3em;

}

.btn_salvar {
    color: #ffffff;
    background-color: #0679F3;
    border: 1px solid #0679F3;
    border-radius: 5px;
    margin: .5em;
    width: 100px;
}

.btn_salvar:hover {
    background-color: #0663c7;
    border: none;
}

.btn_cancelar {
    color: #ffffff;
    background-color: #C1C1C1;
    border: 1px solid #C1C1C1;
    border-radius: 5px;
    margin: .5em;
    width: 100px;
}

.btn_cancelar:hover {
    background-color: #999999;
    border: none;
} */