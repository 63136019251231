.navbar {

    position: sticky;
    display: flex;
    justify-content: space-between;
    background-color: #ffffffe8;
    padding: 0.5rem 2rem;
    height: 5.5em;
    border: 1px solid #eeecec;
}

.img_logo {
    max-width: 70px;
    max-height: 70px;
    align-self: center;
    margin-top: -.3em;
}
.ul {
    display: flex;
    list-style: none;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-bottom: -.2em;
    
}

.li {
    margin: 1rem;
    font-family: 'Source Sans Pro', sans-serif;

}   

li a {   
    color: #2557a3;
    text-decoration: none;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    transition: 0.3s;
    font-size: 18px;

    

}

li a:hover {
    color: #F39C12;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    padding: .3em .8em;
    border-radius: 5px;
    font-weight: 500;
}


/* Estilo Menu Mobile */

.li_mobile {
    margin: .5em;
    font-family: 'Source Sans Pro', sans-serif;
    display: flex;
    justify-content: center;
    margin-left: -2em;

}   

.li_mobile a {   
    color: #ffffff;
    text-decoration: none;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    transition: 0.3s;    
}

.li_mobile a:hover {
    transition: all 0.3s;
    cursor: pointer;
    -webkit-transform: scale(.5);
    transform: scale(1.1 );
    color: #ffffff;
}


.icon {
    background-color: transparent;
    border: none;
    color: #062654;

}
