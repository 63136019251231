.btn_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1em;
    margin-top: 4em;
    margin-bottom: -2em;
}

.btn {
    display: flex;
    background-color: #062654;
    color: aliceblue !important;
    text-decoration: none;
    transition: width .5s;
    cursor: pointer;
    border: none;
    border-radius: 10px;
    text-align: center;
    justify-content: center;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    padding: .2em ;
    width: 80%;

    }

.btn:hover {
    background-color: #1b3354;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 100%;

}

.btn:active {
    color: aliceblue;

}

.icon {
    display: flex;
    flex-wrap: wrap;
    color: aliceblue;
    margin-top: 3px;
    margin-bottom: 2px;
    margin-left: 10px;
}
