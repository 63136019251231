.page {
    display: block;
    background: #fafafa;
    justify-content: center;
    height: auto;
    padding: 2em 4em;
}

.letreiro {
    display: flex;
    align-items: center;
    justify-content: center;
}

.letreiro h1 {
    color: #000033;
    font-size: 2.5em;
    line-height: 2em;
    letter-spacing: -0.035em;
    margin: .5 0 1em 0;
    align-self: center;
}

/* .letreiro h4 {
    color: #000033;
    font-size: 3.5em;
    line-height: 2em;
    letter-spacing: -0.035em;
    margin: .5 0 1em 0;
    align-self: center;
} */

.header,
.header_cnpj {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2em;
    margin-top: 4em;
}

.header h1 {
    color: #000033;
    font-size: 2.5em;
    line-height: 1.5em;
    letter-spacing: -0.025em;
    margin: .5 0 1em 0;
    align-self: center;
}

.header p {
    color: #4b4b4b;
    align-self: center;
}

.header_cnpj h1 {
    color: #000033;
    font-size: 3.5em;
    line-height: 1.5em;
    letter-spacing: 0.1em;
    margin: .5 0 1em 0;
    align-self: center;
}

.header_cnpj p {
    color: #5c83be;
    align-self: center;
    font-size: 1.1em;
    font-weight: 500;
}

.container {
    max-width: 1400px;
    margin: 0 auto;
    background-color: #fff;
    padding: 1.5rem;
    padding-bottom: 2em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 15px;
    height: fit-content;


}

.container_header {
    background-color: #000033;
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 20px;
    padding: .5em 1.5em;
    max-width: 1400px;
    margin: 0 auto;
    margin-bottom: 1.5em;
}

.container_header p {
    font-family: 'Source Sans Pro', sans-serif;
    color: #d8d8d8cc;
    font-size: 16px;
    margin-bottom: -.3em;
}

.form {
    max-width: 1100px;
    margin: 0 auto;
}

.form_group {
    margin-bottom: 1em;
    margin-top: 2em;
    margin-left: 2em;
    margin-right: 2em;

}

.form_group p {
    font-size: 14px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #8b8a8a;
    margin-top: 0;
}

.form_group span {
    font-size: 14px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #8b8a8a;
    margin-top: 0;
}

/* .spanvalidade {
    font-size: 14px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #8b8a8a;
    margin-top: 0;
}

.spanInvalido {
    font-size: 14px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #ff0303d0;
    margin-top: 0;
} */

.form_group h5 {
    margin-bottom: 0;
    color: #140c44
}

.form_group_cnpj {
    margin-bottom: 1em;
    margin-top: 2em;
    margin-left: 2em;
    margin-right: 2em;

}

.form_group_cnpj p {
    font-size: 14px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #ff0303d0;
    margin-top: -1rem;
}

.form_group_cnpj h5 {
    margin-bottom: 0;
    color: #140c44
}

/* 
.title_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #e5edf5;
    padding: .3rem;
    align-items: center;
    align-content: center;
}
.title_container h6 {
color: #000033;
font-family: 'Source Sans Pro', sans-serif;
} */

.form_group_label {
    color: #2596be;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.35em;
    line-height: 1em;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5em;
    margin-top: -.3em;

}

.form_group_label p {
    color: #868686;
    margin-top: -.5em;
    font-weight: 400;
}

.form_group p {
    margin-bottom: -.3em;
}

.label_doc {
    color: #2596be;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5em;
    margin-top: 1em;
}

.form_control_cnpj {
    color: #cfcfcf;
    border-radius: 5px;
    background-color: transparent;
    display: block;
    width: 100%;
    margin-bottom: 1.5em;
    margin-top: -.3em;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form_control_select {
    margin-bottom: 1.5em;
}

.TextAreaGrande {
    color: #303030;
    border: 1px solid #ecebfa;
    border-radius: 5px;
    background-color: transparent;
    margin-bottom: 1.5em;
    margin-top: -.3em;
    appearance: none;
    min-height: 8em;

}

.form_control,
.form_control_textarea,
.select_input .input {

    color: #303030;
    border: 1px solid #ecebfa;
    border-radius: 5px;
    background-color: transparent;
    margin-bottom: 1.5em;
    margin-top: -.3em;
    appearance: none;

}

.form_control_textCenter {

    color: #303030;
    border: 1px solid #ecebfa;
    border-radius: 5px;
    background-color: transparent;
    margin-bottom: 1.5em;
    margin-top: -.3em;
    appearance: none;
    text-align: center;

}

.form_control_textRight {

    color: #303030;
    border: 1px solid #ecebfa;
    border-radius: 5px;
    background-color: transparent;
    margin-bottom: 1.5em;
    margin-top: -.3em;
    appearance: none;
    text-align: right;

}


.form_control_textarea {
    height: 200px;

}

.form_control_textarea::placeholder {
    color: #c7c4c4;

}

.form_control:hover,
.form_control_textarea:hover,
.select_input:hover,
input:hover .form_control_select:hover {
    border: 0.5px solid #80abbbcb;

}

.form_control:focus,
.form_control_textarea:focus,
.select_input:focus,
input:focus .form_control_select:focus {
    border: 2px solid #2596be;
    outline: none;
}


.actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.form_check {
    margin-top: .3em;
    margin-left: 2em;
    align-self: center;
    float: inline-start;
    width: 1em;
    -webkit-transform: scale(1.5);
}


.inserir_btn {
    background-color: #d6eaf8;
    color: #5dade2;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    min-width: fit-content;
    margin-bottom: 1em;
    margin-left: 1em;
}

.inserir_btn>svg {
    fill: #5dade2;
    gap: .2em;
    margin-bottom: .2em;
}

.inserir_btn:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.inserir_btn_Item {
    background-color: #d6eaf8;
    color: #5dade2;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    min-width: fit-content;
    margin-bottom: 1em;
    margin-left: .1em;
    margin-top: 2.2em;
}

.inserir_btn_Item>svg {
    fill: #5dade2;
    gap: .2em;
    margin-bottom: .2em;
}

.inserir_btn_Item:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.Modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Modal_header {
    display: flex;
    flex-direction: column-reverse;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
    margin-bottom: -1em;
    visibility: .5;
}

.Modal_header img {
    padding: 2em 0;
    width: 120px;

}

.Modal_header svg {
    font-size: 40px;
    color: #4b4b4b;
}

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
    color: #707070;
    text-align: center;
    font-size: 14px;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
    margin-bottom: 2em;
}

.Modal_body p {
    font-size: 15px;
    margin-bottom: 1em;
}

.Modal_body p svg {
    font-size: 18px;
    margin-bottom: .2em;
    margin-right: .3em;
    color: #30a565;
}

.Modal_body textarea {
    width: 430px;
    border: 1px solid #adadad;
    border-radius: 10px;
    margin-bottom: 2em;
    min-height: 100px;
}

.Modal_body textarea:focus {
    border: 2px solid #adadad;
    outline: none;
}

.Modal_body h5 {
    font-size: 18px;
}

.label_modal {
    color: #1b4f72;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 1em;

}

.btn_salvar {
    color: #ffffff;
    background-color: #0679F3;
    border: 1px solid #0679F3;
    border-radius: 5px;
    margin: .5em;
    margin-top: 2em;
    width: 100px;
}

.btn_salvar:hover {
    background-color: #0663c7;
    border: none;
}

.btn_cancelar {
    color: #ffffff;
    background-color: #C1C1C1;
    border: 1px solid #C1C1C1;
    border-radius: 5px;
    margin: .5em;
    margin-top: 2em;
    width: 100px;
}

.btn_cancelar:hover {
    background-color: #999999;
    border: none;
}

.container_termos {
    margin-left: 2em;
}

.container_termos strong {
    color: #636363;
    font-size: 14px;
}

.termos {
    border: none;
    background-color: transparent;
    color: #707070;
    font-size: 14px;
}

.termos:hover {
    color: #2596be;
    border-bottom: 1px solid #2596be;
}

.btn {
    display: flex;
    justify-content: center;
    background-color: #1b4f72;
    color: #ffffff;
    text-decoration: none;
    transition: 0.2s;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    padding: .2em;
    float: right;
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    max-height: 2em;
    line-height: 2em;
    padding: 0 2em;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;


}

.btn svg {
    margin-left: .4em;
    margin-bottom: .2em;
}

.btn:hover {
    background-color: #062654;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}

.btn_delete {
    color: #f14141;
    background-color: transparent;
    border: none;
    font-size: 25px;
}

.btn_delete:hover {
    color: #9b2727;
}

.alert {
    color: red;
    font-size: 14px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    margin-top: .5rem;

}