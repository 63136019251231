.page {
    background: #f3f3f4ab;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4vh;
}

.btn_voltar {
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
    border: none;
    padding: .2em;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    margin-top: 1em;
    margin-left: 5em;
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;

}

.btn_voltar svg {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    margin-right: .3em;

}


.btn_voltar:hover {
    border-bottom: 1px solid #222;

}

.box_container {
    min-width: 65vw;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.box_solic {
    background-color: #ffffff;
    border-radius: 10px;
    min-width: 65vw;
    padding: 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0;
}

.box_solic h2 {
    font-weight: 500;
    font-size: 24px;
    color: #626567;
}

.box_solic p {
    color: #707070;
    font-size: 16px;
    margin-bottom: 0;
}

.box_saldo {
    background-color: #ffffff;
    border-radius: 10px;
    max-width: 65vw;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0;
}

.pt_btn {
    background-color: #FEF9E7;
    border-radius: 5px;
    border: 1px solid #FEF9E7;
    font-weight: 600;
    align-self: flex-end;
}

.pt_btn a {
    color: #F1C40F;
    text-decoration: none;
}

.pt_btn a:hover {
    text-decoration: underline;
}

.form_group_label {
    color: #2557a3;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
}

.form_control {
    color: #707070;
    border: 0.5px solid #2557a33f;
    border-radius: 5px;
    background-color: #fff;
    margin-right: 1.5em;
    margin-left: .3em;
    font-size: 14px;
}

.form_control:hover {
    border: 0.5px solid #2557a3;

}

.form_control:focus {
    border: 1px solid #062654;
    outline: none;
}

.saldo_btn {
    background-color: #EBF5FB;
    border-radius: 5px;
    border: 1px solid #EBF5FB;
    font-weight: 600;
    padding: .1em .3em;
    color: #3498DB;
    text-decoration: none;
    margin-top: .5em;
}


.saldo_btn:hover {
    background-color: #3498DB;
    color: #fff;
}

.avisoSaldo {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 1em;
    border-bottom: 2px solid #85929E;
    width: 40vw;
    margin: 0 auto;
}

.avisoSaldo p {
    color: #ff0000;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 1em;
}

.box_itens {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1em;
    display: flex;
    flex-direction: column;
    max-width: 65vw;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0;
    margin: 0 auto;
}

.box_itens h4 {
    font-weight: 500;
    font-size: 16px;
    color: #154360;
    text-align: center;
    padding-bottom: .5em;
}

.box_itens p {
    color: #8d959b;
    font-size: 15px;
    flex-wrap: wrap;
}

.table {
    margin-bottom: .3em;
    border: #d1d1d1;
}

.table thead {
    background-color: #2471A3;
    border: #2471A3;
    color: #ffffff;
    font-weight: 600;
    font-weight: 12px;
}

.table tbody {
    background-color: #ffffff;
    color: #2C3E50;
    font-weight: 12px;

}

/* .box_historico {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1em;
    max-width: 58vw;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0;
}

.box_historico h4 {
    font-weight: 500;
    font-size: 16px;
    color: #154360;
    text-align: center;
    padding-bottom: .5em;

}

.box_historico p {
    color: #707070;
    font-size: 15px;
    padding: .5em;
}

.mapList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    min-width: 55vw;
    min-height: 50vh;
    margin: 0 auto;
    margin-top: 1em;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1em 0;
}

.Card {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.5px dashed #d1d1d1;
    background-color: #ffffff;
    margin: 1em;
    width: 90%;
    height: 135vh;
    font-family: 'Source Sans Pro', sans-serif;
    color: #ad0000;
    font-weight: 600;
    font-size: 20px;
}

.Card a {
    font-family: 'Source Sans Pro', sans-serif;
    color: #636363;
    font-weight: 600;
    font-size: 14px;
} */

.box_gerenciar h4 {
    font-weight: 500;
    font-size: 16px;
    color: #154360;
    text-align: center;
    padding-bottom: .5em;
    font-weight: bold;
}

.box_gerenciar {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: none;
    border-radius: 10px;
    min-width: 65vw;
    max-width: 65vw;
    min-height: 20vh;
    padding: 1em;
    margin-top: 1em;
    margin: 0 auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0;
}

.box_gerenciar li {
    list-style: none;
    border-bottom: 1px solid #e2e2e2;
    font-size: 18px;
    font-weight: 500;
    color: #707070;
}

.form_label_gerenciar {
    font-size: 18px;
    color: #3498DB;
    font-weight: 600;
}

/* .encaminhamento {
    margin-top: 1em;
    margin-bottom: 1em;
}

.box_encaminhamento {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: none;
    border-radius: 10px;
    min-width: 58vw;
    min-height: 220px;
    padding: 1em;
    margin-top: 1em;
    margin: 0 auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0;
}

.box_encaminhamento li {
    list-style: none;
    border-bottom: 1px solid #e2e2e2;
    font-size: 18px;
    font-weight: 500;
    color: #707070;
} */

/* .form_label_encaminhamento {
    font-size: 18px;
    color: #3498DB;
    font-weight: 600;
} */

.btn_send {
    background-color: #ffffff;
    cursor: pointer;
    border: 1px solid #2471A3;
    border-radius: 8px;
    white-space: nowrap;
    color: #2471A3;
    font-size: 16px;
    font-weight: 600;
    margin-right: 1em;
}

.btn_send svg {
    font-size: 18px;
    margin-left: .3em;
    margin-bottom: .1em;
}

.btn_send:hover {
    background-color: #2471A3;
    color: #ffffff;
}

.btn_excluir {
    background-color: #ffffff;
    cursor: pointer;
    border: 1px solid #E74C3C;
    border-radius: 8px;
    white-space: nowrap;
    color: #E74C3C;
    font-size: 16px;
    font-weight: 600;
    margin-right: 1em;
}

.btn_excluir:hover {
    background-color: #E74C3C;
    color: #ffffff;
}

.btn_ativo {
    background-color: #2471A3;
    color: #ffffff;
}

.btn_ativoExcluir {
    background-color: #E74C3C;
    color: #ffffff;
}

.btn_print {
    background-color: #D6DBDF;
    border-radius: 6px;
    font-weight: 600;
    outline: 0;
    border: 0;
    cursor: pointer;
    margin-right: 2em;
    padding: 0 .5em;
}

.btn_print svg {
    color: #34495E;
    font-weight: bold;
    font-size: 20px;
}


.btn_print:hover {
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
    box-shadow: 0 5px 5px rgba(0, 0, 0, .1)
}

.btn_atualizar {
    background-color: #0095ff;
    border: 1px solid transparent;
    border-radius: 5px;
    box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", "Liberation Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.15385;
    margin: 0;
    outline: none;
    padding: 5px .8em;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    white-space: nowrap;
}

.btn_atualizar svg {
    font-size: 18px;
    margin-left: .3em;
    margin-bottom: .2em;
    color: #fff;
}

.btn_atualizar:hover,
.btn_atualizar:focus {
    background-color: #07c;
}

.btn_atualizar:focus {
    box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
}

.btn_atualizar:active {
    background-color: #0064bd;
    box-shadow: none;
}

.btn_regularizacao {
    background-color: #EBF5FB;
    border-radius: 5px;
    border: 1px solid #EBF5FB;
    font-weight: 600;
    padding: .1em .3em;
    color: #3498DB;
    text-decoration: none;
    margin: 0 auto;
    margin-top: 1em;
    width: 15vw;
    font-size: 14px;
}

.btn_regularizacao:hover {
    background-color: #3498DB;
    color: #fff;
}

.VerAquivo_btn {
    appearance: none;
    background-color: #FAFBFC;
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
    box-sizing: border-box;
    color: #24292E;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    list-style: none;
    padding: 6px 16px;
    position: relative;
    transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    word-wrap: break-word;
}

.VerAquivo_btn:hover {
    background-color: #F3F4F6;
    text-decoration: none;
    transition-duration: 0.1s;
}

.VerAquivo_btn:disabled {
    background-color: #FAFBFC;
    border-color: rgba(27, 31, 35, 0.15);
    color: #959DA5;
    cursor: default;
}

.VerAquivo_btn:active {
    background-color: #EDEFF2;
    box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
    transition: none 0s;
}

.VerAquivo_btn:focus {
    outline: 1px transparent;
}

.VerAquivo_btn:before {
    display: none;
}

.VerAquivo_btn:-webkit-details-marker {
    display: none;
}

/* .title_encaminhamento {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #ffffff;
    width: 100%;
    border-bottom: 1px solid rgb(206, 206, 206);
    margin-bottom: 1em;
    margin-top: -.3em;
    padding: .5em;
}

.title_encaminhamento h5 {
    font-size: 22px;
    font-weight: 600;
    color: #2C323F;
} */

.Modal_header {
    display: flex;
    flex-direction: column-reverse;
    color: #4b4b4b;
}

.Modal_header svg {
    font-size: 40px;
    color: #4b4b4b;
}

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #808080;
    text-align: center;
    font-size: 18px;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
}

.Modal_body p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;
    color: #5e5e5e;
    text-align: center;
    font-size: 16px;
}

.Modal_body img {
    width: 40px;
    margin: 0 auto;
}

.Modal_body h6 {

    font-weight: 400;
    color: #ad0101;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    padding: 1em;
    margin-bottom: 1em;
}


.label_modal {
    color: #1b4f72;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 1em;
    margin-top: -.3em;

}

.btn_salvar {
    color: #ffffff;
    background-color: #0679F3;
    border: 1px solid #0679F3;
    border-radius: 5px;
    margin: .5em;
    width: 100px;
}

.btn_salvar:hover {
    background-color: #0663c7;
    border: none;
}

.btn_cancelar {
    color: #ffffff;
    background-color: #C1C1C1;
    border: 1px solid #C1C1C1;
    border-radius: 5px;
    margin: .5em;
    width: 100px;
}

.btn_cancelar:hover {
    background-color: #999999;
    border: none;
}