.page {
    display: block;
    background: #f3f3f4ab;
    justify-content: center;
    min-height: 100vh;
    padding-bottom: 2em;
}

.voltar {
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
    border: none;
    padding: .2em;
    white-space: nowrap;
    margin-top: 3em;
    margin-left: 5em;
    

}

.voltar a {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    text-align: center;
}

.voltar svg {
    color: #222;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    margin-right: .3em;

}


.voltar:hover {
    border-bottom: 1px solid #222;

}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
}

.header h1 {
    color: #2557a3;
    font-size: 2.5em;
    line-height: 1.5em;
    letter-spacing: -0.025em;
    margin: .5 0 1em 0;
    align-self: center;
}

.container {
    max-width: 1400px;
    min-height: fit-content;
    min-height: 40vh;
    margin: 0 auto;
    margin-top: 4em;
    background-color: #fff;
    padding: 1.5rem;
    padding-bottom: 5em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;

}


.select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    margin: 0 auto;

}

.select_lable {
    margin-bottom: 1.5em;
    color: #386cb9;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.35em;
    line-height: 1em;
    font-size: 18px;
    line-height: 1.5em;
    margin-top: -.3em;
}
.inserir_btn {
    font-weight: 600;
    color: #e9b408;
    background-color: #fff3d2;
    border-radius: 5px;
    border: none;
    color: #e9b408;
    padding: 0.4em;
    font-size: 16px;
    line-height: 1.5;
    min-width: 100px;
    margin-bottom: 1.9em;
    margin-left: 1em;
}
.inserir_btn svg {
    margin-bottom: .3em;
    margin-left: .3em;
}

.inserir_btn:hover {
    background-color: #e9b408;
    color: #ffffff;
}

.form_control {
    
    color: #707070;
    border: 0.5px solid #2557a33f;
    border-radius: 5px;
    background-color: transparent;
    margin-bottom: 1.5em;
    margin-top: -.3em;
}

.form_control:hover {
    border: 0.5px solid #2557a3;

}

.form_control:focus {
    border: 2px solid #062654;
    outline: none;
} 

.table {
    width: 90%;
    margin: 0 auto;
}

.table th {
    color: #010913;
    font-size: 18px;
    font-weight: 600;
    background-color: #98d0eb83;
}

.th_btn {
    width: 8em;
}

.btn_delete {
    color: #f14141;
    background-color: transparent;
    border: none;
    font-size: 25px;
}

.btn_delete:hover {
    color: #9b2727;
}

.edit {
    display: flex;
    justify-content: center ;
    background-color: #2557a3;
    transition: 0.2s;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    font-family: 'Source Sans Pro', sans-serif;
    float: right;
    font-weight: 600;
    line-height: 2em;
    padding: 0 1.5em;
    text-align: center;
    white-space: nowrap;
    margin-top: 2em;
    margin-right: .5em;
}

.edit a {
    color: #ffffffe8;
    text-decoration: none;
}

.edit svg {
    font-size: 20px;
    margin-top: -.1em;
}

.edit:hover {
    background-color: #062654;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    /* #2d53af */
}

.Modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
      
}

.Modal_header {
    display: flex;
    flex-direction: column-reverse;
    color: #4b4b4b;
}

.Modal_header svg {
    font-size: 40px;
    color: #df2f2fb4;
}

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    color: #2c2c2c;
    text-align: center;
    font-size: 18px;
    border: none;
    border-radius: 15px;
    background-color: #ffffff;
}

.Modal_body p {
    font-weight: 400;
    color: #808080;
    text-align: center;
    font-size: 18px;
}

.Modal_body img {
    align-self: center;
    width: 40px;
    margin-bottom: .3em;
}