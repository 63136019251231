.page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: transparent;
    font-family: "Source Sans Pro", Helvetica, sans-serif;
    min-height: 100vh;
    /*     
    background-color: #ffffff;
    
    display: block;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1em 2em; */
}

.folha {
    background-color: #ffffff;
    height: 100%;
    width: 900px;
    margin: 0 auto;
    padding: 1em;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    width: 750px;
    margin: 0 auto;
    margin-bottom: 1em;
}

.header img {
    width: 300px;
}

.container_solic {
    display: flex;
    justify-content: center;
    border-radius: 3px;
    padding: .5em;
    margin-bottom: 1em;

}

.container_solic h1 {
    align-self: center;
    font-size: 18px;
    font-weight: 600;
    color: #2C3E50;
    text-decoration: none;

}

/* .container_solic p{
    align-self: center;
    font-size: 20px;
    color: #000000;
    text-decoration: none;   
} */

.container_projeto {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1em;
}

.box_projeto {
    font-size: 14px;
    color: #2C3E50;
}

.box_projeto p {
    margin-bottom: .3em;
}

.box_projeto_banco {
    font-size: 14px;
    color: #2C3E50;
}

.box_projeto_banco p {
    margin-bottom: .3em;
}

.container_justificativa {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    color: #5f666d;
    font-size: 14px;
    width: 100%;
    margin-bottom: 1em;
}

.container_tabela {
    display: block;
    padding: 1em;
    background-color: #F8F9F9;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 1em;
}

.table {
    margin-bottom: .3em;
    border: #d1d1d1;
}

.table thead {
    background-color: #2471A3;
    border: #2471A3;
    color: #ffffff;
    font-weight: 600;
}

.table tbody {
    background-color: #ffffff;
    color: #2C3E50;

}

.container_fonte {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    color: #2C3E50;
}

.container_cadastro {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1.5em;
    font-size: 14px;
}

.container_cadastro strong {
    color: #2C3E50;
}

.container_cadastro p {
    color: #5f666d;
    margin-bottom: .3em;
}

.container_dadosbancarios {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1em;
    font-size: 14px;
}

.container_dadosbancarios h4 {
    font-size: 16px;
    color: #808B96;
    font-weight: 700;
}

.container_dadosbancarios strong {
    color: #2C3E50;
}

.container_dadosbancarios p {
    color: #5f666d;
}

.Modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Modal_header {
    display: flex;
    flex-direction: column-reverse;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
    margin-bottom: -1em;
    visibility: .5;
    font-size: 18px;

}

.Modal_header img {
    padding: 2em 0;
    width: 120px;

}

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    color: #2c2c2c;
    text-align: center;
    font-size: 18px;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
}