.page {
    display: block;
    background: #fafafa;
    justify-content: center;
    height: auto;
    padding: 2em 4em;
}

.letreiro {
    display: flex;
    align-items: center;
    justify-content: center;
}

.letreiro h1 {
    color: #000033;
    font-size: 2.5em;
    line-height: 2em;
    letter-spacing: -0.035em;
    margin: .5 0 1em 0;
    align-self: center;
}

.Modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Modal_body p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
    color: #808080;
    text-align: center;
    font-size: 16px;
}

.btn_atualizar {
    background-color: #0095ff;
    border: 1px solid transparent;
    border-radius: 5px;
    box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", "Liberation Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.15385;
    margin: 0;
    outline: none;
    padding: 5px .8em;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    white-space: nowrap;
}

.btn_atualizar svg {
    font-size: 18px;
    margin-left: .3em;
    margin-bottom: .2em;
    color: #fff;
}

.btn_atualizar:hover,
.btn_atualizar:focus {
    background-color: #07c;
}

.btn_atualizar:focus {
    box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
}

.btn_atualizar:active {
    background-color: #0064bd;
    box-shadow: none;
}

.Modal_header {
    display: flex;
    flex-direction: column-reverse;
    color: #4b4b4b;
}

.Modal_header svg {
    font-size: 40px;
    color: #4b4b4b;
}

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #808080;
    text-align: center;
    font-size: 18px;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
}

.Modal_body p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;
    color: #5e5e5e;
    text-align: center;
    font-size: 16px;
}

.Modal_body img {
    width: 40px;
    margin: 0 auto;
}

.Modal_body h6 {

    font-weight: 400;
    color: #ad0101;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    padding: 1em;
    margin-bottom: 1em;
}


.label_modal {
    color: #1b4f72;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 1em;
    margin-top: -.3em;

}