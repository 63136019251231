.page {
    background: #f3f3f4ab;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container {
    min-width: 50%;
    background-color: #fff;
    padding: 2em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1em;
    margin-top: -1em;
    margin-bottom: 1em;
}

.header h4 {
    color: #34495E;
    margin-left: -.5em;
}


.header label {
    color: #3f82af9a;
}

.header input {
    border: 1px solid #3f82af9a;
    border-radius: 5px;
    color: #85929E;
}

.header input:hover {
    border: 1px solid #0090c5;
}

.header input:focus {
    border: 2px solid #0090c5;
}

.novo_usuario {
    background-color: #2980B9;
    border: #2980B9;
    border-radius: 5px;
}

.novo_usuario a {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
}

.novo_usuario svg {
    font-size: 20px;
    margin-top: -.3em;
}

.novo_usuario:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #2471A3;
}

.ativo {
    font-weight: 600;
    color: #50cd89;
    background-color: #e8fff3;
    border-radius: 5px;
    width: fit-content;
    padding: 0 .5em;
    font-size: 12px;
}

.nao_ativo {
    font-weight: 600;
    color: #f1416c;
    background-color: #fff5f8;
    border-radius: 5px;
    width: fit-content;
    padding: 0 .5em;
    font-size: 12px;
}

.btn_edit {
    background-color: #ecdef5;
    border-radius: 6px;
    font-weight: 600;
    outline: 0;
    border: 0;
}

.btn_edit svg {
    color: #7239ea;
    font-weight: bold;
    font-size: 18px;
}

.btn_edit:hover {
    cursor: pointer;
    border: none;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
    box-shadow: 0 5px 5px rgba(0, 0, 0, .1)
}


.btn_senhaAcesso {
    background-color: #FBFCFC;
    border: 0;
    border-radius: .5rem;
    box-sizing: border-box;
    color: #111827;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.25rem;
    padding: .2rem .5rem;
    text-align: center;
    text-decoration: none #D1D5DB solid;
    text-decoration-thickness: auto;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-bottom: .5em;
    width: 10vw;
}

.btn_senhaAcesso:hover {
    background-color: #ebebeb;
}

.btn_senhaAcesso:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.btn_senhaAcesso:focus-visible {
    box-shadow: none;
}

.Modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Modal_header {
    display: flex;
    flex-direction: column-reverse;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
    margin-bottom: -1em;
    visibility: .5;
    font-size: 18px;

}

.Modal_header img {
    padding: 2em 0;
    width: 120px;

}

.Modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    color: #2c2c2c;
    text-align: center;
    font-size: 18px;
    border: none;
    border-radius: 15px 15px;
    background-color: #ffffff;
}